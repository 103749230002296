/* eslint-disable react/display-name */
import React, { useMemo, useRef, useState } from "react";
import useResizeObserver from "use-resize-observer";
import { Button, DateRangePicker, Toolbar, CountIndicator } from "@puzzle/ui";
import { breakpoints } from "@puzzle/theme";
import { FilterStatus, useTransactionsPage } from "./TransactionsProvider";
import ExtendedFilter from "./ExtendedFilter";
import TopTransactionsModal from "./TopTransactionsModal/TopTransactionsModal";
import { useActiveCompany } from "components/companies";
import { MembershipRole } from "graphql/types";
import { queryTypes, useQueryState } from "next-usequerystate";
import { UpdateCategoryMetricsLocations } from "./hooks/useSingleTransaction";
import { zIndex } from "ve";

const SearchBar = ({ forceOpen }: { forceOpen?: boolean }) => {
  const { filter, setFilter } = useTransactionsPage();

  return useMemo(
    () => (
      <Toolbar.Search
        placeholder="Search by description..."
        value={filter.descriptor}
        onChange={(descriptor) => setFilter({ descriptor })}
        // undefined instead of false is intentional
        open={forceOpen || undefined}
        css={{ width: forceOpen ? "100%" : undefined }}
      />
    ),
    [filter.descriptor, forceOpen, setFilter]
  );
};

const FilterToolbar = React.memo(function FilterToolbar() {
  const { filter, setFilter, rangePresets, totals, loading } = useTransactionsPage();
  const { membershipRole, company } = useActiveCompany();
  const [categorizerOpen, setCategorizerOpen] = useQueryState(
    "helpMeCategorize",
    queryTypes.boolean.withDefault(false)
  );

  const toolbarRef = useRef<HTMLDivElement>(null);
  const [forceSearchOpen, setForceSearchOpen] = useState(false);
  useResizeObserver({
    ref: toolbarRef,
    onResize: ({ width, height }) => {
      if (width) {
        setForceSearchOpen(width < breakpoints.s);
      }
    },
  });

  const searchFilter = useMemo(() => <SearchBar forceOpen={forceSearchOpen} />, [forceSearchOpen]);

  const dateFilter = useMemo(
    () => (
      <DateRangePicker
        value={[filter.start, filter.end]}
        presets={rangePresets}
        preset={filter.rangePreset ?? undefined}
        onChange={([start, end], rangePreset) => {
          setFilter({ start, end, rangePreset });
        }}
        showInputs
        popoverCss={{ zIndex: zIndex("menu") }}
      />
    ),
    [filter.end, filter.rangePreset, filter.start, rangePresets, setFilter]
  );

  const topTransactionsButton = useMemo(
    () =>
      membershipRole &&
      (["Admin", "Bookkeeper"] as MembershipRole[]).includes(membershipRole) && (
        <TopTransactionsModal
          open={categorizerOpen}
          onOpenChange={(open) => setCategorizerOpen(open || null, { shallow: true })}
          trigger={
            <Toolbar.Item>
              <Button size="compact" variant="secondary">
                Help me categorize
              </Button>
            </Toolbar.Item>
          }
          metricsLocation={UpdateCategoryMetricsLocations.TransactionsHelpMeCategorizeButton}
        />
      ),
    [categorizerOpen, membershipRole, setCategorizerOpen]
  );

  return (
    <Toolbar
      ref={toolbarRef}
      css={{
        // TODO Need designers to define the responsive layout.
        // This is a cheap way to overflow to another row.
        flexWrap: "wrap",
        // Alsooo.. The collapsible sidebar might complicate media queries. Do we need a Container Query polyfill?
      }}
    >
      {searchFilter}

      {dateFilter}

      <ExtendedFilter />

      <Toolbar.ToggleButton
        pressed={filter.status === FilterStatus.NotCategorized}
        onPressedChange={(pressed) =>
          setFilter({
            status: pressed ? FilterStatus.NotCategorized : null,
          })
        }
      >
        Not categorized
      </Toolbar.ToggleButton>

      {company?.features.assistedCategorizationEnabled && (
        <Toolbar.ToggleButton
          pressed={filter.categorizedByAI === true}
          onPressedChange={(pressed) =>
            setFilter({
              categorizedByAI: pressed ? true : null,
            })
          }
        >
          Categorized by AI
        </Toolbar.ToggleButton>
      )}

      <Toolbar.ToggleButton
        pressed={filter.assignedToMe === true}
        onPressedChange={(pressed) =>
          setFilter({
            assignedToMe: pressed ? true : null,
          })
        }
      >
        Assigned to me{" "}
        {!loading && <CountIndicator shape="square">{totals.assignedToMeCount}</CountIndicator>}
      </Toolbar.ToggleButton>

      <Toolbar.ToggleButton
        pressed={filter.showHighPriorityReview === true}
        onPressedChange={(pressed) =>
          setFilter({
            showHighPriorityReview: pressed ? true : null,
            ledgerCoaKeys: pressed ? filter.ledgerCoaKeys : [],
            minAmount: pressed ? filter.minAmount : null,
          })
        }
        css={{
          borderColor: "$purple700",
          boxShadow: !filter.showHighPriorityReview
            ? "0 0 10px 5px rgba(128, 0, 128, 0.2)"
            : "none",
        }}
      >
        High Priority Review
      </Toolbar.ToggleButton>

      {topTransactionsButton}
    </Toolbar>
  );
});

export default FilterToolbar;
