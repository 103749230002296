import React, { useMemo, useEffect, useCallback, useState } from "react";
import * as yup from "yup";
import {
  useForm,
  UseFormReturn,
  useFormContext,
  FormProvider,
  SubmitHandler,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { CalendarDateString } from "scalars";

import { parseDate, today } from "@puzzle/utils";
import { useToasts } from "@puzzle/ui";

import useAppRouter from "lib/useAppRouter";
import useCategories from "components/common/hooks/useCategories";
import { MembershipRole, InvoiceStatus, CreateInvoiceInput } from "graphql/types";
import { useActiveCompany, useCompanyDateFormatter } from "components/companies";
import { Route } from "lib/routes";

import {
  useGetInvoiceQuery,
  SingleInvoiceFragment,
  useCreateInvoiceMutation,
  useUpdateInvoiceMutation,
} from "../graphql.generated";
import { glAmountToDisplayString } from "../../Accounting/GeneralLedger/LedgerAccountViewBody";
import { calculateServiceDuration, errorToastConfig } from "../shared";
import Analytics, { FeatureFlag, isPosthogFeatureFlagEnabled } from "lib/analytics";
import { useExpenseCategories } from "components/common/CategoriesFilter";
import { isEqual, omit, sum } from "lodash";
import { useFormPersist } from "components/common/useFormPersist";
import { FormValues } from "./types";
import { calculateNumSchedules, toCreateInvoiceInput, toUpdateInvoiceInput } from "./mapping";
import { queryTypes, useQueryStates, UseQueryStatesKeysMap } from "next-usequerystate";

type InvoiceFormContextType = Omit<
  ReturnType<typeof useInvoiceForm>,
  keyof ReturnType<typeof useForm>
>;

const checkHasLedgerChanges = (form: UseFormReturn<FormValues, any>) => {
  const startLedgerValues = {
    ...omit(form.formState.defaultValues, [
      "dueDate",
      "customer",
      "lines",
      "discountLines",
      "shippingLines",
    ]),
    lines: form.formState.defaultValues?.lines?.map((l) => omit(l, "product")),
    discountLines: form.formState.defaultValues?.discountLines?.map((l) => omit(l, "product")),
    shippingLines: form.formState.defaultValues?.shippingLines?.map((l) => omit(l, "product")),
  };
  const currentLedgerValues = {
    ...omit(form.getValues(), ["dueDate", "customer", "lines", "discountLines", "shippingLines"]),
    lines: form.getValues()?.lines?.map((l) => omit(l, "product")),
    discountLines: form.getValues()?.discountLines?.map((l) => omit(l, "product")),
    shippingLines: form.getValues()?.shippingLines?.map((l) => omit(l, "product")),
  };
  return !isEqual(startLedgerValues, currentLedgerValues);
};

export const durationOptions = Array(99)
  .fill(0)
  .map((_, i) => {
    const duration = (i + 1).toString();
    return {
      value: duration,
      label: duration,
    };
  });

const isLineAmountChange = (name?: string) => {
  return (
    !!name &&
    (name.startsWith("lines") ||
      name.startsWith("discountLines") ||
      name.startsWith("shippingLines") ||
      name.startsWith("taxLines")) &&
    name.endsWith(".amount")
  );
};

const isEntireLineChange = (name?: string) => {
  return !!name && ["lines", "discountLines", "shippingLines", "taxLines"].includes(name);
};

export const isPostedOrPaid = (invoiceStatus?: InvoiceStatus): boolean => {
  if (invoiceStatus) {
    return [InvoiceStatus.Posted, InvoiceStatus.Paid].includes(invoiceStatus);
  } else {
    return false;
  }
};

export const scheduleSchema = yup
  .object({
    serviceDuration: yup.string().required(),
    startDate: yup
      .string()
      .required()
      .test("date", (value) => Boolean(value && parseDate(value))),
    accountingConfigurationId: yup.string().nullable().default(null).notRequired(),
  })
  .nullable()
  .default(null)
  .notRequired();

const validationSchema = yup.object({
  dueDate: yup.string().required(),
  issueDate: yup.string().required(),
  customer: yup.object().required(),
  externalId: yup.string(),
  description: yup.string(),
  lines: yup
    .array()
    .of(
      yup.object({
        description: yup.string(),
        product: yup.object().nullable(),
        category: yup.object().required(),
        amount: yup.number().required(),
        schedule: scheduleSchema,
      })
    )
    .min(1)
    .required(),
  shippingLines: yup
    .array()
    .of(
      yup.object({
        description: yup.string(),
        category: yup.object().required(),
        amount: yup.number().required(),
      })
    )
    .required(),
  discountLines: yup
    .array()
    .of(
      yup.object({
        description: yup.string(),
        category: yup.object().required(),
        amount: yup.number().required(),
        schedule: scheduleSchema,
      })
    )
    .required(),
  total: yup
    .number()
    .required()
    .test("total", (value) => Boolean(value && value > 0)),
});

const statusToAction = (
  newInvoiceStatus: InvoiceStatus,
  isCreate: boolean,
  existingInvoiceStatus?: InvoiceStatus
): string => {
  // only two expected statuses are Posted and Draft here
  if (isCreate) {
    if (newInvoiceStatus === InvoiceStatus.Draft) {
      return "drafted";
    } else {
      return "posted";
    }
  } else {
    if (
      existingInvoiceStatus === InvoiceStatus.Draft &&
      newInvoiceStatus === InvoiceStatus.Posted
    ) {
      // update mutation is used to move invoice to posted status
      // when in draft status (when the Post button is clicked
      // on a draft state invoice)
      return "posted";
    } else {
      return "updated";
    }
  }
};

const statusToTitle = (
  newInvoiceStatus: InvoiceStatus,
  isCreate: boolean,
  existingInvoiceStatus?: InvoiceStatus
): string => {
  // only two expected statuses are Posted and Draft here
  if (
    isCreate ||
    (existingInvoiceStatus === InvoiceStatus.Draft && newInvoiceStatus === InvoiceStatus.Posted)
  ) {
    return `Invoice ${statusToAction(newInvoiceStatus, isCreate, existingInvoiceStatus)}`;
  } else {
    if (newInvoiceStatus === InvoiceStatus.Draft) {
      return "Draft changes saved";
    } else {
      return "Changes posted";
    }
  }
};

/**
 * Syncs related form values.
 */
const useExistingInvoiceValueSync = ({
  form,
  existingInvoice,
}: {
  form: UseFormReturn<FormValues>;
  existingInvoice?: SingleInvoiceFragment;
}) => {
  const { timeZone } = useActiveCompany();
  const { categoriesByPermaKey } = useCategories();

  useEffect(() => {
    if (!existingInvoice) {
      return;
    }

    form.reset({
      total: parseFloat(existingInvoice.amount.amount),
      customer: existingInvoice.customer || undefined,
      issueDate: existingInvoice.issueDate || undefined,
      dueDate: existingInvoice.dueDate || undefined,
      description: existingInvoice.description || "",
      externalId: existingInvoice.shortId || "",
      lines: existingInvoice.lines.map((line) => ({
        product: line.product || undefined,
        description: line.description || "",
        category: line.coaKey ? categoriesByPermaKey?.[line.coaKey] : undefined,
        amount: parseFloat(line.amount.amount),
        id: line.invoiceItemId,
        segments: line.classSegments,
        schedule: line.schedule?.id
          ? {
              id: line.schedule?.id ?? undefined,
              serviceDuration: calculateServiceDuration({
                startDay: line.schedule?.startDay,
                endDay: line.schedule?.endDay,
                timeZone,
              }).toString(),
              startDate: line.schedule?.startDay ?? undefined,
              accountingConfigurationId:
                line.schedule.contractLine?.accountingConfigurationId ?? undefined,
            }
          : undefined,
      })),
      shippingLines: existingInvoice.shippingLines.map((line) => ({
        product: undefined,
        description: line.description || "",
        category: line.ledgerCategory
          ? categoriesByPermaKey?.[line.ledgerCategory.coaKey]
          : undefined,
        amount: parseFloat(line.subtotal.amount),
        id: line.invoiceShippingLineId,
        segments: line.classSegments,
      })),
      discountLines: existingInvoice.discountLines.map((line) => ({
        product: undefined,
        description: line.description || "",
        category: line.ledgerCategory
          ? categoriesByPermaKey?.[line.ledgerCategory.coaKey]
          : undefined,
        amount: -parseFloat(line.amount.amount),
        id: line.invoiceDiscountLineId,
        segments: line.classSegments,
        schedule: line.schedule?.id
          ? {
              id: line.schedule?.id ?? undefined,
              serviceDuration: calculateServiceDuration({
                startDay: line.schedule?.startDay,
                endDay: line.schedule?.endDay,
                timeZone,
              }).toString(),
              startDate: line.schedule?.startDay ?? undefined,
            }
          : undefined,
      })),
    });

    // Revalidate
    form.trigger();
  }, [existingInvoice, form, categoriesByPermaKey, timeZone]);
};

/**
 * Syncs related form values.
 */
const useFormValueSync = ({
  form: { setValue, watch },
  existingInvoice,
}: {
  form: UseFormReturn<FormValues>;
  existingInvoice?: SingleInvoiceFragment;
}) => {
  const dateFormatter = useCompanyDateFormatter({ dateStyle: "short" });

  useEffect(() => {
    const subscription = watch(
      ({ lines, discountLines, shippingLines, issueDate, customer, description }, { name }) => {
        const lineAmountChanged = isLineAmountChange(name);
        const entireLineChanged = isEntireLineChange(name); // Line was added or removed
        const toDescription = `${customer?.name} - ${dateFormatter.format(
          parseDate(issueDate as CalendarDateString)
        )}`;

        /**
         * Recalculates total when line amounts change.
         */
        if (lines && (lineAmountChanged || entireLineChanged)) {
          const lineTotal = sum(lines.map((l) => l?.amount || 0));
          const discountLineTotal = sum(discountLines?.map((l) => l?.amount || 0));
          const shippingLineTotal = sum(shippingLines?.map((l) => l?.amount || 0));

          // will need to change when/if tax lines are editable to use form
          const taxLineTotal = sum(
            existingInvoice?.taxLines.map((l) => Number(l?.amount.amount) || 0)
          );

          setValue("total", lineTotal + discountLineTotal + shippingLineTotal + taxLineTotal, {
            shouldValidate: true,
            shouldDirty: true,
            shouldTouch: true,
          });
        }

        /**
         * Keeps line item invoice dates in sync with top level invoice date.
         */
        if (name === "issueDate") {
          lines?.forEach((_, i) => {
            setValue(`lines.${i}.issueDate`, issueDate as CalendarDateString);
          });
        }

        /**
         * Update description with customer and issue date
         */
        if (customer && issueDate?.toString && (name === "issueDate" || name === "customer")) {
          setValue("description", toDescription, {
            shouldValidate: true,
            shouldDirty: true,
            shouldTouch: true,
          });
        }

        /**
         * Update line description with top level description if:
         * - there's only one line
         * - description was auto populated from vendor
         */
        if (name === "description") {
          lines?.forEach((_, i) => {
            if (lines.length === 1 || (customer && description === toDescription)) {
              setValue(`lines.${i}.description`, description ?? "");
            }
          });
        }
      }
    );

    return () => subscription.unsubscribe();
  }, [setValue, watch, dateFormatter, existingInvoice]);
};

const addAnalytics = (invoice: SingleInvoiceFragment, numberOfSchedules: number) => {
  if (invoice.status === InvoiceStatus.Draft) {
    Analytics.invoicePosted({
      invoiceId: invoice.id,
      totalRows: invoice.lines.length,
      numberOfSchedules,
    });
  } else {
    Analytics.invoiceDrafted({
      invoiceId: invoice.id,
      totalRows: invoice.lines.length,
      numberOfSchedules,
    });
  }
};

const useCreateInvoiceFromFormData = ({
  clearPersistedForm,
}: {
  clearPersistedForm: () => void;
}) => {
  const { company } = useActiveCompany<true>();
  const companyId = company.id;
  const { toast } = useToasts();
  const { goToPathWithCurrentQuery } = useAppRouter();
  const dateFormatter = useCompanyDateFormatter({ dateStyle: "short" });
  const [createInvoiceMutation, { called, error, loading }] = useCreateInvoiceMutation();
  const [updateInvoiceMutation] = useUpdateInvoiceMutation();

  const goToNewInvoice = useCallback(
    (invoiceId: string) => {
      const t = setTimeout(() => {
        goToPathWithCurrentQuery(`${Route.invoices}/${invoiceId}`, ["id"]);
        return () => clearTimeout(t);
      });
    },
    [goToPathWithCurrentQuery]
  );

  const addSuccessToast = useCallback(
    (invoice: SingleInvoiceFragment, isCreate: boolean, existingInvoiceStatus?: InvoiceStatus) => {
      const amount = glAmountToDisplayString(invoice.amount.amount);
      const action = statusToAction(invoice.status, isCreate, existingInvoiceStatus);
      let message = `You ${action} a invoice`;
      if (invoice.description) {
        message += ` for ${invoice.description}`;
      }
      message += ` for ${amount}`;
      if (invoice.dueDate) {
        message += ` that is due on ${dateFormatter.format(parseDate(invoice.dueDate))}`;
      }

      toast({
        title: statusToTitle(invoice.status, isCreate, existingInvoiceStatus),
        message: message,
      });
    },
    [toast, dateFormatter]
  );

  const addErrorToast = useCallback(() => {
    toast(errorToastConfig);
  }, [toast]);

  return {
    mutate: useCallback(
      async (formData: FormValues, isDraft: boolean, overrides?: Partial<CreateInvoiceInput>) => {
        const createInvoiceInput = toCreateInvoiceInput(companyId, formData, isDraft, overrides);

        await createInvoiceMutation({
          variables: {
            input: toCreateInvoiceInput(companyId, formData, isDraft, overrides),
          },
          onCompleted: ({ createInvoice }) => {
            addSuccessToast(createInvoice, true);
            goToNewInvoice(createInvoice.id);
            addAnalytics(createInvoice, calculateNumSchedules(createInvoiceInput.lines));
            clearPersistedForm();
          },
          onError: () => {
            addErrorToast();
          },
        });
      },
      [
        companyId,
        createInvoiceMutation,
        addErrorToast,
        addSuccessToast,
        goToNewInvoice,
        clearPersistedForm,
      ]
    ),
    updateMutate: useCallback(
      async (
        formData: FormValues,
        invoiceId: string,
        isDraft: boolean,
        existingInvoiceStatus: InvoiceStatus
      ) => {
        const updateInvoiceInput = toUpdateInvoiceInput(invoiceId, formData, isDraft);
        await updateInvoiceMutation({
          variables: {
            input: updateInvoiceInput,
          },
          onCompleted: ({ updateInvoice }) => {
            addSuccessToast(updateInvoice.invoice, false, existingInvoiceStatus);
            goToNewInvoice(updateInvoice.invoice.id);
            addAnalytics(updateInvoice.invoice, calculateNumSchedules(updateInvoiceInput.lines));
            clearPersistedForm();
          },
          onError: () => {
            addErrorToast();
          },
        });
      },
      [updateInvoiceMutation, addErrorToast, addSuccessToast, goToNewInvoice, clearPersistedForm]
    ),
    mutateSucceeded: called && !error && !loading,
  };
};

export const useInvoiceForm = ({ id }: { id?: string }) => {
  const { categoriesByPermaKey } = useCategories();
  const { expenseCategories } = useExpenseCategories();
  const [submittingStatus, setSubmittingStatus] = useState<`${InvoiceStatus}` | null>(null);
  const { timeZone, membershipRole } = useActiveCompany<true>();
  const issueDate = today(timeZone);
  const { data, loading: existingInvoiceLoading } = useGetInvoiceQuery({
    variables: id ? { id } : undefined,
    skip: !id,
  });

  const existingInvoice = data?.invoice;

  const form = useForm<FormValues>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      issueDate: issueDate.toString(),
      dueDate: issueDate.add({ months: 1 }).toString(),
      externalId: "",
      description: "",
      total: 0,
      lines: [
        {
          issueDate: issueDate.toString(),
          description: "",
        },
      ],
    },
  });

  const [queryFilter, setQueryFilter] = useQueryStates<UseQueryStatesKeysMap<{ edit: boolean }>>({
    edit: queryTypes.boolean,
  });

  const [readOnly, setReadOnlyState] = useState(
    existingInvoice?.status !== InvoiceStatus.Draft &&
      (membershipRole === MembershipRole.Viewer || !!existingInvoice)
  );

  const setReadOnly = useCallback(
    (s: boolean) => {
      setReadOnlyState(s);
      setQueryFilter({ edit: !s });
    },
    [setReadOnlyState, setQueryFilter]
  );

  const [isDirtyDeep, setIsDirtyDeep] = useState(false);
  const [hasLedgerChanges, setHasLedgerChanges] = useState(false);

  const detectFormChanges = useCallback(() => {
    setIsDirtyDeep(!isEqual(form.formState.defaultValues, form.getValues()));
    if (form.formState.defaultValues) {
      setHasLedgerChanges(checkHasLedgerChanges(form));
    }
  }, [setIsDirtyDeep, setHasLedgerChanges, form]);

  useEffect(() => {
    const subscription = form.watch(() => {
      detectFormChanges();
    });
    return () => subscription.unsubscribe();
  }, [form, hasLedgerChanges, detectFormChanges]);

  useEffect(() => {
    setReadOnlyState(
      existingInvoice?.status !== InvoiceStatus.Draft &&
        (membershipRole === MembershipRole.Viewer || !!existingInvoice) &&
        !(
          Boolean(queryFilter.edit) &&
          Boolean(isPosthogFeatureFlagEnabled(FeatureFlag.EditPostedInvoice))
        )
    );
    detectFormChanges();
  }, [existingInvoice, id, membershipRole, queryFilter, detectFormChanges]);

  // If invoice exists, then create a separately session storage value.
  const { clearPersistedForm } = useFormPersist<FormValues>({
    entity: "invoice",
    setValue: form.setValue,
    watch: form.watch,
    skip: Boolean(id),
  });

  useFormValueSync({ form, existingInvoice });
  useExistingInvoiceValueSync({ form, existingInvoice });

  const createInvoiceFromFormData = useCreateInvoiceFromFormData({ clearPersistedForm });

  const onSaveDraft = useMemo(() => {
    const saveDraft: SubmitHandler<FormValues> = async (formData) => {
      setSubmittingStatus(InvoiceStatus.Draft);
      if (existingInvoice) {
        await createInvoiceFromFormData.updateMutate(
          formData,
          existingInvoice.id,
          true,
          existingInvoice.status
        );
      } else {
        await createInvoiceFromFormData.mutate(formData, true);
      }
    };

    return form.handleSubmit((data) => saveDraft(data));
  }, [form, createInvoiceFromFormData, existingInvoice]);

  const onPost = useMemo(() => {
    const post: SubmitHandler<FormValues> = async (formData) => {
      setSubmittingStatus(InvoiceStatus.Posted);
      if (existingInvoice) {
        await createInvoiceFromFormData.updateMutate(
          formData,
          existingInvoice.id,
          false,
          existingInvoice.status
        );
      } else {
        await createInvoiceFromFormData.mutate(formData, false);
      }
      setReadOnly(true);
    };

    return form.handleSubmit((data) => post(data));
  }, [form, createInvoiceFromFormData, existingInvoice, setReadOnly]);

  // when it's a posted invoice calculate the difference from the form value amounts
  // and the existing amount since we will not be supporting total amount changes
  const differenceToPostedInvoiceTotal = isPostedOrPaid(existingInvoice?.status)
    ? Number(existingInvoice?.amount.amount) - form.getValues("total")
    : 0;

  return {
    form,
    categoriesByPermaKey,
    existingInvoice,
    differenceToPostedInvoiceTotal,
    onSaveDraft,
    onPost,
    submittingStatus,
    categories: expenseCategories,
    mutateSucceeded: createInvoiceFromFormData.mutateSucceeded,
    loading: existingInvoiceLoading,
    readOnly,
    setReadOnly,
    isEditor: membershipRole !== MembershipRole.Viewer,
    isDirtyDeep,
    hasLedgerChanges,
  };
};

const InvoiceFormContext = React.createContext<InvoiceFormContextType | null>(null);

export const useInvoiceFormContext = () => {
  const formContext = useFormContext<FormValues>();
  const customContext = React.useContext(InvoiceFormContext);

  if (!customContext) {
    throw new Error("Must be used in InvoiceFormProvider");
  }

  return useMemo(() => ({ ...formContext, ...customContext }), [customContext, formContext]);
};

export const InvoiceFormProvider = ({
  form,
  readOnly,
  setReadOnly,
  isEditor,
  loading,
  categories,
  categoriesByPermaKey,
  onPost,
  onSaveDraft,
  submittingStatus,
  mutateSucceeded,
  existingInvoice,
  differenceToPostedInvoiceTotal,
  isDirtyDeep,
  hasLedgerChanges,
  ...props
}: ReturnType<typeof useInvoiceForm> & {
  children: React.ReactNode;
}) => {
  const customContextValues = useMemo(
    () => ({
      form,
      readOnly,
      setReadOnly,
      isEditor,
      loading,
      categories,
      categoriesByPermaKey,
      onPost,
      onSaveDraft,
      submittingStatus,
      mutateSucceeded,
      existingInvoice,
      differenceToPostedInvoiceTotal,
      isDirtyDeep,
      hasLedgerChanges,
    }),
    [
      form,
      readOnly,
      setReadOnly,
      isEditor,
      loading,
      categories,
      categoriesByPermaKey,
      onPost,
      onSaveDraft,
      submittingStatus,
      mutateSucceeded,
      existingInvoice,
      differenceToPostedInvoiceTotal,
      isDirtyDeep,
      hasLedgerChanges,
    ]
  );

  return (
    <InvoiceFormContext.Provider value={customContextValues}>
      <FormProvider {...form} {...props} />
    </InvoiceFormContext.Provider>
  );
};
