import React, { useState } from "react";
import useRippling from "./useRippling";
import { IntegrationsListItemBaseProps } from "../List";
import { IntegrationConnectionStatus, IntegrationType } from "graphql/types";
import Logo from "./Logo";
import { IntegrationDisplayComponent } from "../shared/types";
import Analytics, { FeatureFlag, isPosthogFeatureFlagEnabled } from "lib/analytics";
import { useRouter } from "next/router";
import { ConnectRipplingModal } from "./ConnectRipplingModal";

interface RipplingListItemProps {
  DisplayComponent: IntegrationDisplayComponent<
    React.PropsWithChildren<IntegrationsListItemBaseProps>
  >;
  companyId: string;
  parentModalOnOpen?(open: boolean): void;
}
export const TITLE = "Rippling";
export const SUBTITLE = "Link employment costs for your financial statements and taxes.";
const PAID_ADD_ON_TOOLTIP =
  "Rippling is a paid add-on. Rippling charges $2 per employee per month for API access";
const RipplingListItem = ({
  DisplayComponent,
  companyId,
  parentModalOnOpen,
}: RipplingListItemProps) => {
  const { onClickConnect, connection, loading } = useRippling({ companyId });
  const [showModal, setShowModal] = useState(false);
  const router = useRouter();
  const isOnboarding = router.pathname.includes("intro");
  const isRipplingIntegrationConnectionEnabled = isPosthogFeatureFlagEnabled(
    FeatureFlag.RipplingIntegrationConnection
  );
  const isExistingConnection =
    connection?.status !== undefined &&
    [IntegrationConnectionStatus.Ok, IntegrationConnectionStatus.Error].includes(
      connection?.status
    );

  const onClickConnectWrapper = () => {
    if (isOnboarding) {
      parentModalOnOpen?.(false);
      // TODO: Let GW know that rippling was selected
      Analytics.onboardingRipplingSelected({ providerName: "Rippling" });
    } else {
      setShowModal(true);
    }
  };

  // We want to show Rippling in the Integrations list if,
  // 1. The company already has an active Rippling connection (even if the feature flag is disabled)
  // 2. The feature flag is enabled
  if (isExistingConnection || isRipplingIntegrationConnectionEnabled) {
    return (
      <>
        <DisplayComponent
          integrationType={IntegrationType.Rippling}
          connectIntegration={onClickConnectWrapper}
          title={TITLE}
          logo={<Logo />}
          connection={connection}
          loading={loading}
          isPaidAddOn={true}
          tooltipContent={PAID_ADD_ON_TOOLTIP}
        />
        <ConnectRipplingModal
          open={showModal}
          onOpenChange={() => {
            setShowModal(false);
          }}
        />
      </>
    );
  } else {
    return null;
  }
};

export default RipplingListItem;
