/*
  
  EXPLANATION:

  Z-index values are relative. Arrays are perfect for indicating relative order.

  TO USE:

  import { zIndex } from "ve";

  zIndex("tooltip") // gets a number value for the tooltip z-index

  TO ADD A NEW ELEMENT:

  Just add the new element to the zIndexElements array.
  
  Find a place where the new element should be in the stack and add it there.
  The first element will be zero, then 100, 200, etc.

  It is ok to have many different names in the array. Go crazy. (Though reuse anything that makes sense.)
  As long as the order makes sense, the elements will render correctly in the DOM.

*/

const zIndexElements = [
  "background",
  "tag",
  "stickyHeader",
  "popover",
  "tooltip",
  "loader",
  "menu",
  "menuTooltip",
  "subMenu",
  "subMenuTooltip",
  "modal",
  "modalPopover",
  "modalTooltip",
  "modalMenu",
  "modalMenuTooltip",
  "modalSubMenu",
  "modalSubMenuTooltip",
] as const;

type ZIndexElement = typeof zIndexElements[number];

// do not import getZIndex directly, use import { zIndex } from "ve";
export const getZIndex = (element: ZIndexElement) => {
  return zIndexElements.indexOf(element) * 100; // The first element will be zero, then 100, 200, etc.
};

//  ----------------------  TESTING  ----------------------

// Custom type to represent an array of unique strings
type UniqueArray<T extends string> = T[] & { __unique: true };

// Type assertion function to ensure the array does not contain duplicates
function assertUnique<T extends string>(arr: readonly T[]): asserts arr is UniqueArray<T> {
  const uniqueElements = new Set(arr);
  if (uniqueElements.size !== arr.length) {
    throw new Error(
      `You cannot have duplicate strings in the zIndexElements array.
      Please check zIndexElements and remove any duplicates.`
    );
  }
}

// Validate the zIndexElements array for duplicates
assertUnique(zIndexElements);
