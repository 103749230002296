import React from "react";
import { Dialog } from "@puzzle/ui";
import { Button, Stack, Text, vars } from "ve";
import { PaidAddOn } from "@puzzle/icons";
import RipplingLogo from "./Logo";
import { RipplingStepCard } from "./ConnectRipplingStepCard";
import { useActiveCompany } from "components/companies";
import { IntegrationType } from "graphql/types";
import useRippling from "./useRippling";
import useMonetization from "components/monetization/useMonetization";
import { SubscriptionBillingCycle } from "components/monetization/types";

export type RipplingStep = {
  title: string;
  subText?: string;
  onClick?: () => void;
};

const connectRipplingSteps: RipplingStep[] = [
  { title: "Connect to Rippling", subText: "$2/employee/mo" },
  { title: "Upgrade to a paid plan" },
];

export const ConnectRipplingModal = ({
  open,
  onOpenChange,
}: {
  open: boolean;
  onOpenChange: () => void;
}) => {
  const { company, isOnPaidPlan } = useActiveCompany<true>();
  const { onClickConnect } = useRippling({ companyId: company.id });
  const { showUpgradeModal, setSelectedCycle } = useMonetization();
  const hasRipplingConnection = Boolean(
    company?.integrationConnections?.find((c) => c.type === IntegrationType.Rippling)
  );

  const _showUpgradeModal = async () => {
    await setSelectedCycle(SubscriptionBillingCycle.Monthly);
    showUpgradeModal({ disableAnnualPlan: true });
  };

  const buttonText = hasRipplingConnection ? "Upgrade" : "Connect to Rippling";
  const buttonOnClick = hasRipplingConnection ? _showUpgradeModal : onClickConnect;

  return (
    <Dialog size="medium" open={open} onOpenChange={onOpenChange}>
      <Dialog.Title>
        <Stack direction={"horizontal"} style={{ alignItems: "center" }}>
          <div style={{ width: "40px", height: "40px" }}>
            <RipplingLogo circular={true} />
          </div>
          Connect to Rippling
        </Stack>
      </Dialog.Title>
      <Dialog.Body style={{ padding: vars.space["2h"] }}>
        <Stack direction="vertical">
          <Text variant="bodyS" color="gray100">
            Gain a more complete view of your financials by connecting your payroll data in Rippling
            to Puzzle.
          </Text>
          <Stack direction="horizontal">
            <PaidAddOn width={18} height={18}></PaidAddOn>
            <Text color="gray300">Rippling is a paid add-on.</Text>
          </Stack>
          <Text variant="bodyS" color="gray100">
            To start ingesting your Rippling data:
          </Text>
          <RipplingStepCard
            step={connectRipplingSteps[0]}
            stepNumber={1}
            href={"#"}
            onClick={onClickConnect}
            completed={hasRipplingConnection}
          />

          <RipplingStepCard
            step={connectRipplingSteps[1]}
            stepNumber={2}
            href={"#"}
            onClick={_showUpgradeModal}
            completed={isOnPaidPlan}
          />
        </Stack>
      </Dialog.Body>
      <Dialog.Footer>
        <Button onClick={() => buttonOnClick()}>{buttonText}</Button>
      </Dialog.Footer>
    </Dialog>
  );
};
