import { useEffect } from "react";
import { useRouter } from "next/router";
import { omitBy, isNil, uniq } from "lodash";
import { UserProfile } from "@auth0/nextjs-auth0";
import {
  DestinationPlugin,
  Event,
  PluginType,
  SpecialEventType,
  IdentifyOperation,
  Result,
  SuccessResponse,
  Status,
} from "@amplitude/analytics-types";
import * as amplitude from "@amplitude/analytics-browser";
import posthog from "posthog-js";
import { H } from "highlight.run";

import { SelfFragment, SelfMembershipFragment } from "components/users/graphql.generated";
import Config, { IS_DEV, IS_PROD, IS_STAGING } from "lib/config";
import { ActiveCompanyFragment } from "components/companies";

import { Ampli, ReportDownloadedProperties } from "./ampli";
import Bugsnag from "../bugsnag";
import { FLAGS_ENABLED_ON_DEV, FLAGS_ENABLED_ON_STAGING } from "./featureFlags";
import { Company, MembershipRole, UserPosition } from "graphql/types";

import { RudderAnalytics } from '@rudderstack/analytics-js';

export type ReportType = ReportDownloadedProperties["reportType"];

// Prevent dispatching Posthog analytics events on staging. This will allow staging to move over to the
// production posthog configuration so that feature flags will be fully synced between both environments
// but without staging polluting production posthog analytics.
const SKIP_POSTHOG_ANALYTICS = IS_STAGING;

/*
 ** gtag is a global variable we wrap around the GTM by Google Tag Manager.
 ** Use the pztag version below to send events to the GTM.
 */

const GTM_PRE = "PZ_";

export const pztag = (
  name: string,
  eventParams?: Gtag.ControlParams | Gtag.EventParams | Gtag.CustomParams
) => {
  if (typeof gtag !== "undefined") {
    gtag("event", GTM_PRE + name, eventParams);
  }
};

// The posthog config takes either a boolean or an object.
// Counter-intuitively, autocapture: false doesn't mean no autocapture!
// For that the following object (with empty arrays, not undefined or false!) is required.
const AutocaptureNone = {
  url_allowlist: [],
  dom_event_allowlist: [],
  element_allowlist: [],
  css_selector_allowlist: [],
};

const rudderAnalytics = new RudderAnalytics();

try {
  rudderAnalytics.load(
    Config.RUDDER_KEY || "", 
    "https://puzzlepatufg.dataplane.rudderstack.com",
    {
    anonymousIdOptions: {
      autoCapture: {
            enabled: true
          }
      }
    }
  );
} catch (err) {
  console.log(`Error loading Rudderstack object: ${err}`);
}


/**
 * Ampli plugin that adds logging and custom destinations for tracking calls.
 */
export class PuzzleAmpliPlugin implements DestinationPlugin {
  name = "puzzle-ampli-plugin";
  type = PluginType.DESTINATION as const;
  currentId = process.env.BUILD_ID;

  /**
   * setup() is called on plugin installation
   * example: client.add(new AddEventIdPlugin());
   */
  async setup() {
    // Consider calling this in _app so autocapture can run a bit sooner?
    if (typeof window !== "undefined" && !Config.IS_TEST) {
      posthog.init(Config.POSTHOG_KEY || "fake token", {
        // Configured here: https://console.cloud.google.com/net-services/cdn/backendService/details/posthog?project=valencia-prod
        api_host: "https://ph.puzzle.io",
        autocapture: IS_PROD ? true : AutocaptureNone, // We may want to remove this long-term... Worth a shot?
        disable_session_recording: IS_PROD ? true : false,
        secure_cookie: true,
        cross_subdomain_cookie: true,

        // TODO use these in dev/staging?
        // {
        //   loaded: function (ph) {
        //       ph.opt_out_capturing()
        //   },
        // }
      });

      // TODO figure out e2e sitch.. check their email via posthog?
      // Note: override goes on top of existing flags, but it's replaced each time.
      // It persists until you do:
      // posthog.feature_flags.override(false);
      if (Config.IS_LOCAL_DEVELOPMENT || IS_DEV) {
        posthog.featureFlags.override(FLAGS_ENABLED_ON_DEV);
      }

      if (IS_STAGING) {
        posthog.featureFlags.override(FLAGS_ENABLED_ON_STAGING);
      }
    }

    return undefined;
  }

  /**
   * execute() is called on each event instrumented
   * example: client.track('New Event');
   */
  async execute(context: Event) {
    let highlightSession: any;

    try {
      if (process.env.NODE_ENV === "production") {
        highlightSession = await H.getSessionDetails();
      }
    } catch (err) {
      // ignore
    }

    return new Promise<Result>((resolve) => {
      const {
        event_type: eventType,
        event_properties: eventProperties,
        user_id: userId,
        user_properties: userProperties,
        group_properties: groupProperties,
        groups,
      } = context;
      const userTraits = userProperties?.[IdentifyOperation.SET];
      const groupTraits = groupProperties?.[IdentifyOperation.SET];

      const callback = () => {
        resolve({ event: context, code: 200, message: "" });
      };

      if (typeof window === "undefined" || Config.IS_TEST || SKIP_POSTHOG_ANALYTICS) {
        callback();
        return;
      }

      switch (eventType) {
        case SpecialEventType.IDENTIFY:
          if (process.env.NODE_ENV === "development") {
            console.debug("[Analytics]", "identify:", userId, userTraits);
          }

          posthog.identify(userId, userTraits);

          Bugsnag.setUser(userId, userTraits.email, userTraits.name);
          H.identify(userTraits.email, userTraits);

          rudderAnalytics.identify(
            userId as string, 
            userTraits
          );

          break;

        case SpecialEventType.GROUP_IDENTIFY:
          if (groups?.company) {
            if (process.env.NODE_ENV === "development") {
              console.debug("[Analytics]", "groupIdentify:", groups.company, groupTraits);
            }

            posthog.group("company", groups.company, groupTraits);

            rudderAnalytics.group(groups.company.id, groupTraits);
          }
          break;

        default:
          if (process.env.NODE_ENV === "development") {
            console.debug("[Analytics]", "track:", eventType, eventProperties);
          }

          posthog.capture(eventType, {
            ...eventProperties,
            sessionUrl: highlightSession?.urlWithTimestamp,
          });

          pztag(eventType.replaceAll(" ", "_").toLowerCase(), eventProperties);
          H.track(
            eventType,
            omitBy(eventProperties, isNil) as Record<string, string | boolean | number>
          );

          rudderAnalytics.track(eventType, { ...eventProperties});

          break;
      }
    });
  }
}

const ampli = new Ampli();
ampli.load({
  client: {
    apiKey: "ignore",
    configuration: {
      logLevel: 0,
      transportProvider: {
        // Mock out sending to Amplitude
        send: () =>
          Promise.resolve<SuccessResponse>({
            status: Status.Success,
            statusCode: 200,
            body: {
              eventsIngested: 1,
              payloadSizeBytes: 1,
              serverUploadTime: 1,
            },
          }),
      },
    },
  },
});
ampli.client.add(new PuzzleAmpliPlugin());

/**
 * A wrapper around the Ampli client. It provides:
 * - type-safe methods per event
 * - identifyUser
 * - identifyCompany
 * - reset
 *
 * Do not use the internal amplitude client or identify methods.
 */
const Analytics = Object.assign(ampli, {
  identifyUser: (
    self?: SelfFragment | null,
    userProfile?: UserProfile,
    memberships?: SelfMembershipFragment[],
    membership?: SelfMembershipFragment,
    company?: ActiveCompanyFragment | null
  ) => {
    if (SKIP_POSTHOG_ANALYTICS || !self?.id) {
      return;
    }

    const identify = new amplitude.Identify()
      .set("id", self.id)
      .set("createdAt", self.createdAt)
      .set("email", self.email!)
      .set("name", self.name!)
      .set("position", membership?.position ?? "")
      .set("isOwner", memberships?.some((membership) => membership.isOwner) ?? false)
      .set("ownerOf", memberships?.filter((membership) => membership.isOwner).map((membership) => membership.companyId) ?? [])
      .set("roles", uniq(memberships?.map((membership) => membership.role) ?? []));

    if (userProfile?.given_name) {
      identify
        .set("firstName", userProfile?.given_name as string)
        .set("lastName", userProfile?.family_name as string);
    }

    if (company) {
      identify
        .set("company", {
          "id": company.trackingId || company.id,
          "name": company.name,
          "type": company.type,
          "orgType": company.orgType || "",
          "createdAt": company.createdAt,
          "hasHistoricalData": company.hasHistoricalData || false,
          "timeZone": company.timeZone || "",
          "referralCode": company.referralCode || "",
          "revenueModel": company.revenueModel || ""
        });
    }

    ampli.client.identify(identify, {
      user_id: self.id,
    });
  },

  identifyCompany: (
    company?: ActiveCompanyFragment | null,
    selfMembership?: SelfMembershipFragment,
    traits?: {
      partner?: string;
    },
    additionalProperties: Record<string, any> = {}
  ) => {
    if (SKIP_POSTHOG_ANALYTICS || !company) {
      return;
    }

    const identify = new amplitude.Identify();
    identify
      .set("id", company.trackingId || company.id)
      .set("name", company.name)
      .set("type", company.type)
      .set("orgType", company.orgType || "")
      .set("createdAt", company.createdAt)
      .set("hasHistoricalData", company.hasHistoricalData || false)
      .set("timeZone", company.timeZone || "")
      .set("referralCode", company.referralCode || "");
    if (company.revenueModel) {
      identify.set("revenueModel", company.revenueModel);
    }

    // cookie-based but it's the best we can do
    if (traits?.partner) {
      identify.set("partner", traits.partner);
    }

    Object.entries(additionalProperties).forEach(([key, value]) => {
      identify.set(key, value);
    });

    ampli.client.groupIdentify("company", company?.id, identify);

    posthog.register({
      $$role: selfMembership?.role,
      $$isOwner: selfMembership?.isOwner,
    });

    Bugsnag.addMetadata("company", {
      id: company.id,
      name: company.name,
      type: company.type,
    });
  },

  resetIdentity: () => {
    if (SKIP_POSTHOG_ANALYTICS) {
      return;
    }

    Bugsnag.clearMetadata("user");
    Bugsnag.setUser(undefined, undefined, undefined);
    if (posthog.__loaded) {
      posthog.reset();
      posthog.unregister("$$role");
      posthog.unregister("$$isOwner");
    }

    rudderAnalytics.reset();
  },

  setHasBookkeeper: (company: Company, userMemberships?: SelfMembershipFragment[]) => {
    // TODO: this will be iterated to use position in lieu of role
    const memberships = company.memberships ?? [];
    const membershipRoles: MembershipRole[] = [...memberships.map((membership) => membership.role)];
    const membershipPositions = [
      ...memberships.map((membership) => membership.position),
      ...(userMemberships?.map(userMembership => userMembership.position) ?? [])
    ];

    Analytics.identifyCompany(
      company,
      undefined,
      undefined,
      {
        "hasBookkeeper": membershipRoles.includes(MembershipRole.Bookkeeper) || 
          membershipPositions.includes(UserPosition.OutsourcedAccountantOrCfo)
      }
    );
  }
});

export const usePageAnalytics = (email: string | null | undefined, anonymous_id: string | null | undefined) => {
  /*
    anonymous_id: is the device_id, which can change, but if passed to through can be used
    by systems (like CustomerIO) to merge past events to identified users.
  */
  const router = useRouter();

  useEffect(() => {
    // Track page views
    const handleRouteChange = () => {
      if (SKIP_POSTHOG_ANALYTICS) {
        return;
      }

      posthog.capture("$pageview", {
        title: document.title, // not normally collected by PostHog
        email: email || "foo@bar.puzzle",
          anonymous_id: anonymous_id
      });

      // Rudderstack pageview
      rudderAnalytics.page("$pageview");
      

      // disabling for now, switching to container, will revisit soon
      /*
      if (process.env.GTAG_ID) {
        gtag("config", process.env.GTAG_ID, {
          page_location: window.location.href
          page_title: document.title,
          page_path: url,
        });
      }*/
    };

    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);
};

export const onboardingCompletedGtag = () => {
  pztag("onboarding_completed");
};

export * from "./ampli";
export * from "./featureFlags";
export default Analytics;
