import React from "react";
import { useActiveCompany } from "components/companies";
import useSelf from "components/users/useSelf";
import Analytics, { FeatureFlag, isPosthogFeatureFlagEnabled } from "lib/analytics";
import { useCallback, useEffect, useMemo, useState } from "react";
import { create } from "zustand";
import {
  useAddItemsToStripeSubscriptionMutation,
  useCreateStripeCheckoutSessionMutation,
  useRequestAdditionalServicesMutation,
} from "./graphql.generated";
import config from "lib/config";
import { PricingPlanNames, SubscriptionBillingCycle, AddOnNames, Plan, AddOn } from "./types";
import links from "lib/links";
import { queryTypes, useQueryState } from "next-usequerystate";
import { ADDONS, PLANS, PlanTitle } from "./PricingModal/plans";
import { reportError } from "lib/errors";
import { AdditionalServices, MembershipRole } from "graphql/types";
import { useToasts } from "@puzzle/ui";
import { joinWithCommasAnd } from "./PricingModal/utils";
import { Cookies } from "lib/cookies";
import useAppRouter from "lib/useAppRouter";
import { parseCookies } from "nookies";
import { useRouter } from "next/router";
interface MonetizationStoreProps {
  isModalVisible: boolean;
  isConfirmationModalVisible: boolean;
  disableAnnualPlan: boolean;
  setDisableAnnualPlan: (disable: boolean) => void;
  addonsForConfirmationModal: AddOn[];
  hideUpgradeModal: () => void;
  showUpgradeModal: () => void;
  selectedAddons: AddOnNames[];
  toggleAddon: (addonName: AddOnNames) => void;
  clearAddons: () => void;
  showAddonConfirmationModal: (addons: AddOn[]) => void;
  hideAddonConfirmationModal: () => void;
}

type CreateStripeSessionCheckoutParams = {
  plan?: Plan;
  addons?: AddOn[];
  bookkeeping?: boolean;
  subscriptionCycle: SubscriptionBillingCycle;
};

const useMonetizationStore = create<MonetizationStoreProps>((set) => ({
  isModalVisible: false,
  isConfirmationModalVisible: false,
  disableAnnualPlan: false,
  addonsForConfirmationModal: [],
  showUpgradeModal: () =>
    set((state: MonetizationStoreProps) => ({ ...state, isModalVisible: true })),
  hideUpgradeModal: () => {
    set((state: MonetizationStoreProps) => ({
      ...state,
      isModalVisible: false,
      disableAnnualPlan: false,
      selectedAddons: [],
    }));
  },
  setDisableAnnualPlan: (disable: boolean) => {
    set((state: MonetizationStoreProps) => ({ ...state, disableAnnualPlan: disable }));
  },
  selectedAddons: [],
  toggleAddon: (addonName: AddOnNames) => {
    set((state: MonetizationStoreProps) => {
      const old = [...state.selectedAddons];
      return old.includes(addonName)
        ? { ...state, selectedAddons: old.filter((a) => a !== addonName) }
        : { ...state, selectedAddons: [...old, addonName] };
    });
  },
  clearAddons: () => {
    set((state: MonetizationStoreProps) => ({ ...state, selectedAddons: [] }));
  },
  showAddonConfirmationModal: (addons: AddOn[]) => {
    set((state: MonetizationStoreProps) => ({
      ...state,
      addonsForConfirmationModal: addons,
      isConfirmationModalVisible: addons.length > 0,
    }));
  },
  hideAddonConfirmationModal: () => {
    set((state: MonetizationStoreProps) => ({
      ...state,
      addonsForConfirmationModal: [],
      isConfirmationModalVisible: false,
    }));
  },
}));

export default function useMonetization() {
  const {
    isModalVisible,
    showUpgradeModal: _showUpgradeModal,
    hideUpgradeModal,
    selectedAddons,
    toggleAddon,
    clearAddons,
    showAddonConfirmationModal,
    hideAddonConfirmationModal,
    addonsForConfirmationModal,
    isConfirmationModalVisible,
    setDisableAnnualPlan,
    disableAnnualPlan,
  } = useMonetizationStore();
  const router = useRouter();
  const [_addItemsToStripeSubscription] = useAddItemsToStripeSubscriptionMutation();
  const [_createStripeCheckoutSession] = useCreateStripeCheckoutSessionMutation();
  const [_requestAdditionalServices] = useRequestAdditionalServicesMutation();
  const { self } = useSelf();
  const { toast } = useToasts();
  const { goToBilling } = useAppRouter();
  const {
    company,
    currentSubscription,
    isOnFreeTrial,
    isOnPaidPlan,
    currentAddons,
    membershipRole,
  } = useActiveCompany<true>();
  const [loadingStripe, setLoadingStripe] = useState(false);
  const [bookkeepingEnabled, setBookkeepingEnabled] = useQueryState(
    "bookkeeping",
    queryTypes.boolean.withDefault(false)
  );

  const [selectedPlan, setSelectedPlan] = useQueryState(
    "selectedPlan",
    queryTypes.stringEnum(PLANS.map((p) => p.title))
  );

  const [selectedCycle, setSelectedCycle] = useQueryState(
    "billingCycle",
    queryTypes
      .stringEnum(Object.values(SubscriptionBillingCycle))
      .withDefault(SubscriptionBillingCycle.Yearly)
  );

  // Automation was renamed Advanced, handle old URLs
  useEffect(() => {
    if (selectedPlan === "Automation") {
      setSelectedPlan(PlanTitle.Advanced);
    }
  }, [selectedPlan, setSelectedPlan]);

  const [showUpgradeModalQuery, setShowUpgradeModalQuery] = useQueryState(
    "showUpgradeModal",
    queryTypes.boolean.withDefault(false)
  );

  const requestAdditionalServices = useCallback(
    async (services: AdditionalServices[]) => {
      return _requestAdditionalServices({
        variables: { input: { companyId: company.id, services } },
      });
    },
    [company, _requestAdditionalServices]
  );

  const allowYearlySubscription = isPosthogFeatureFlagEnabled(FeatureFlag.SubscriptionYearly);

  const effectiveSelectedCycle = useMemo(() => {
    return allowYearlySubscription
      ? selectedCycle || SubscriptionBillingCycle.Yearly
      : SubscriptionBillingCycle.Monthly;
  }, [selectedCycle, allowYearlySubscription]);

  /**
   * Ideally to subscribe to addons when already subscribed
   * to a paid plan.
   */
  const addItemsToStripeSubscription = async (addons: AddOn[]) => {
    if (addons.length === 0) return;

    // List of all addons selected to checkout
    const stripeIds =
      addons?.reduce<string[]>((result, addon) => {
        if (!addon || !addon.priceYearId || !addon.priceId) return result;
        result.push(
          effectiveSelectedCycle === SubscriptionBillingCycle.Yearly
            ? addon.priceYearId
            : addon.priceId
        );
        return result;
      }, []) ?? [];

    _addItemsToStripeSubscription({
      variables: {
        input: {
          companyId: company.id,
          stripePriceIds: stripeIds,
        },
      },
    })
      .then(() => {
        // show toasts based on PricingModal query params useEffect
        toast({
          duration: 10000,
          message: (
            <>
              You are now subscribed to {joinWithCommasAnd(addons.map((addon) => addon.title))}{" "}
              {effectiveSelectedCycle ? "monthly" : "yearly"}.
            </>
          ),
          onAction: () => goToBilling(),
          actionText: "View in Plans & Billing",
          title: "Subscription updated",
        });
        // hide upgrade modal
        hideUpgradeModal();
      })
      .catch(() => {
        toast({
          duration: 5000,
          message: "Your addon billing cycle must match your subscription cycle.",
          title: "Oops!",
          status: "error",
        });
      });
  };

  const onCheckout = async () => {
    setLoadingStripe(true);
    Analytics.checkoutReviewCtaClicked();
    // To be refactored, now pricing modal can be opened with the user
    // already subscribe to a plan.
    let plan = PLANS.find((t) => t.title === selectedPlan);
    if (plan?.id === currentSubscription && isOnPaidPlan) {
      plan = undefined;
    }

    // List of addons that can user can subscribe
    const subscribableAddons = selectedAddons.filter((addon) => !currentAddons.includes(addon));
    // List for Addons selected for checkout
    const addons = ADDONS.filter((x) => subscribableAddons.includes(x.id));

    // If there are no changes to the plan and user is on Startup plan
    // and user wants so subscribe to addons, we should manage this
    // internally via modal.
    if (
      plan === undefined &&
      currentSubscription === PricingPlanNames.PAID_PLAN_1 &&
      addons.length > 0
    ) {
      showAddonConfirmationModal(addons);
      setLoadingStripe(false);
      // Prevents stripe checkout.
      return;
    }

    // To be refactored, now need to include Addons
    if (plan) {
      changeCompanyTier(plan.id);
    }

    // If enterprise plan was selected then open a
    // mailto.
    if (plan?.id === PricingPlanNames.CUSTOM_PLAN) {
      window.open(
        "mailto:support@puzzle.com?subject=Puzzle%20Enterprise",
        "_blank",
        "noopener noreferrer"
      );
      setLoadingStripe(false);
      return;
    }

    // All plans without a priceId or priceYearId will be treated as undefined
    const stripeCheckoutSession = await createStripeCheckoutSession({
      plan,
      addons,
      bookkeeping: bookkeepingEnabled,
      subscriptionCycle: effectiveSelectedCycle,
    });

    if (stripeCheckoutSession === false) {
      reportError("No stripeIds to upgrade");
      toast({
        status: "warning",
        message: "Oops! It seems there are no changes to your current subscription.",
      });
      setLoadingStripe(false);
      return;
    }

    const url = stripeCheckoutSession.data?.createStripeCheckoutSession.url;

    // If theres no URL, then something went absolutely wrong
    // or theres no plane associated to that priceId.
    if (!url) {
      reportError("Could not generate Stripe checkout URL", {
        company: company.id,
        plan,
        addons,
      });
      setLoadingStripe(false);
      return;
    }

    router.push(url);

    setLoadingStripe(false);
  };

  /**
   * if redirectIfSubscribed is false always shows the upgrade modal
   * if not, will open Stripe Customer Portal only if the user is subscribed, otherwise
   * it will open upgrade modal
   */
  const showUpgradeModal = useCallback(
    ({ redirectIfSubscribed = false, disableAnnualPlan = false } = {}) => {
      // Only admins can upgrade
      if (membershipRole === MembershipRole.Admin) {
        setShowUpgradeModalQuery(true, { shallow: true });
      } else {
        return;
      }
      if (disableAnnualPlan) {
        setDisableAnnualPlan(disableAnnualPlan);
      }
      if (!redirectIfSubscribed) {
        _showUpgradeModal();
        return;
      }
      if (currentSubscription === PricingPlanNames.FREE || (isOnFreeTrial && !isOnPaidPlan)) {
        _showUpgradeModal();
      } else {
        router.push(links.stripeCustomerPortal);
      }
    },
    [
      membershipRole,
      currentSubscription,
      _showUpgradeModal,
      isOnFreeTrial,
      isOnPaidPlan,
      router,
      setDisableAnnualPlan,
      setShowUpgradeModalQuery,
    ]
  );

  /**
   * This create a request to GW to generate a stripe checkout session.
   * This will have a url in data with a successful return and cancelation
   * return in it.
   * @returns The fetch result of the create stripe checkout session or
   * false if plan is undefined and list of addons is empty.
   */
  const createStripeCheckoutSession = ({
    plan,
    addons,
    bookkeeping,
    subscriptionCycle,
  }: CreateStripeSessionCheckoutParams) => {
    let successUrl = config.ROOT_URL + "?paymentSuccess=true";
    let cancelUrl = config.ROOT_URL + "?paymentSuccess=false";
    if (plan) {
      successUrl += `&plan=${plan.title}`;
      cancelUrl += `&plan=${plan.title}`;
    }
    if (addons && addons.length > 0) {
      addons.forEach((addon) => {
        successUrl += `&addon=${addon.title}`;
        cancelUrl += `&addon=${addon.title}`;
      });
    }
    // Getting bookkeeping = true on after a payment cancelation
    // will also run the inbox workflow for additional services
    // so we just enable it for the success flow
    if (bookkeeping) {
      successUrl += `&bookkeeping=true`;
    }
    successUrl += `&cycle=${subscriptionCycle}`;

    // List of all plans selected to checkout. This will always be
    // a 0 <= array <= 1
    const planPriceId =
      plan && plan.priceYearId && plan.priceId
        ? effectiveSelectedCycle === SubscriptionBillingCycle.Yearly
          ? [plan.priceYearId]
          : [plan.priceId]
        : [];
    // List of all addons selected to checkout
    const addonPriceIds =
      addons?.reduce<string[]>((result, addon) => {
        if (!addon || !addon.priceYearId || !addon.priceId) return result;
        result.push(
          effectiveSelectedCycle === SubscriptionBillingCycle.Yearly
            ? addon.priceYearId
            : addon.priceId
        );
        return result;
      }, []) ?? [];

    const stripePriceIds = [...planPriceId, ...addonPriceIds];

    if (stripePriceIds.length === 0) return false;

    const cookies = parseCookies();
    const facebookClickId: string | undefined = cookies[Cookies.FacebookClickId];
    const facebookPixelId: string | undefined = cookies[Cookies.FacebookPixelId];

    return _createStripeCheckoutSession({
      variables: {
        input: {
          companyId: company.id,
          successUrl,
          cancelUrl,
          stripePriceIds,
          facebookPixelId,
          facebookClickId,
        },
      },
    });
  };

  const changeCompanyTier = useCallback(
    (tierKey: string) => {
      if (tierKey !== "") {
        Analytics.paidTrialUpgradePlanSelected({
          companyId: company.id,
          userId: self?.id ?? undefined,
          currentPlan: tierKey,
          previousPlan: "",
          upgradedToPremium: tierKey !== PricingPlanNames.FREE,
          downgradedToFree: tierKey === PricingPlanNames.FREE,
        });
      }
    },
    [company.id, self?.id]
  );

  return {
    loadingStripe,
    setLoadingStripe,
    showUpgradeModalQuery,
    setShowUpgradeModalQuery,
    bookkeepingEnabled,
    setBookkeepingEnabled,
    selectedPlan,
    setSelectedPlan,
    selectedAddons,
    toggleAddon,
    clearAddons,
    selectedCycle: effectiveSelectedCycle,
    setSelectedCycle,
    changeCompanyTier,
    isModalVisible,
    showUpgradeModal,
    hideUpgradeModal,
    createStripeCheckoutSession,
    onCheckout,
    requestAdditionalServices,
    addonsForConfirmationModal,
    hideAddonConfirmationModal,
    isConfirmationModalVisible,
    addItemsToStripeSubscription,
    disableAnnualPlan,
  };
}
