import React, { useState } from "react";
import GenericConnectionCard from "../ListItem/GenericConnectionCard";
import { IntegrationType } from "graphql/types";
import { Button } from "ve";
import { ConnectRipplingModal } from "./ConnectRipplingModal";

const description =
  "Gain a more complete view of your financials by connecting your payroll data in Rippling to Puzzle.";
const subDescription = "Note: Rippling is a paid add-on.";

export const RipplingListItemPlaceholder = () => {
  const [showModal, setShowModal] = useState(false);

  const renderConnectButton = () => {
    return (
      <Button
        variant="secondary"
        shape="pill"
        onClick={() => {
          setShowModal(true);
        }}
      >
        Connect
      </Button>
    );
  };
  return (
    <>
      <GenericConnectionCard
        financialInstitutionName={IntegrationType.Rippling}
        action={renderConnectButton()}
        description={description}
        subDescription={subDescription}
        isPaidAddOn={true}
      />
      <ConnectRipplingModal
        open={showModal}
        onOpenChange={() => {
          setShowModal(false);
        }}
      />
    </>
  );
};
