import React from "react";

import InstitutionLogo from "../bank/InstitutionLogo";
import {
  connectionContainerStyle,
  connectionHeaderRecipe,
  connectionTitleInnerStyle,
  connectionTitleStyle,
  connectionIconRecipe,
  connectionCardStyle,
  connectionDescriptionStyle,
  connectionSubDescriptionStyle,
} from "./connectionCardStyles.css";
import CardIcon from "@mui/material/ListItemIcon";

const GenericConnectionCard = ({
  financialInstitutionName,
  action,
  description = "",
  subDescription = "",
  isPaidAddOn = false,
}: {
  financialInstitutionName: string;
  action?: React.ReactNode;
  description?: string;
  subDescription?: string;
  isPaidAddOn?: boolean;
}) => {
  const testId = `financial-institution-section-${financialInstitutionName}`;
  const testIdTitle = `financial-institution-title-${financialInstitutionName}`;

  return (
    <div className={connectionCardStyle} data-testid={testId}>
      <div className={connectionContainerStyle}>
        <div style={{ marginBottom: 0 }} className={connectionHeaderRecipe({ disabled: false })}>
          <div className={connectionTitleStyle}>
            <CardIcon className={connectionIconRecipe({ disabled: false })}>
              <InstitutionLogo
                institution={{ name: financialInstitutionName }}
                circular={true}
                isPaidAddOn={isPaidAddOn}
              />
            </CardIcon>
            <div className={connectionTitleInnerStyle}>
              <span data-testid={testIdTitle}>{financialInstitutionName}</span>
            </div>
          </div>
          <div style={{ marginLeft: "auto" }}>{action ? action : null}</div>
        </div>
      </div>
      <div className={connectionDescriptionStyle}>{description}</div>
      <div className={connectionSubDescriptionStyle}>{subDescription}</div>
    </div>
  );
};

export default GenericConnectionCard;
