import React from "react";
import { styled, Switch } from "@puzzle/ui";

const SwitchItem = styled("div", {
  display: "flex",
  gap: "$1",
});

export const ColumnVisibilitySwitch = ({
  label,
  checked,
  onCheckedChange,
  draggable = false,
}: {
  label: string;
  checked: boolean;
  onCheckedChange: (checked: boolean) => void;
  draggable?: boolean;
}) => {
  return (
    <SwitchItem>
      <Switch
        css={{
          justifyContent: "space-between",
          width: "140px",
          fontSize: "12px",
        }}
        switchCss={{ width: 23, minWidth: 23, height: 16 }}
        thumbCss={{
          width: 12,
          height: 12,
          transform: `translateX(1px)`,
          '&[data-state="checked"]': {
            transform: `translateX(10px)`,
          },
        }}
        checked={checked}
        onCheckedChange={onCheckedChange}
      >
        {label}
      </Switch>
    </SwitchItem>
  );
};
