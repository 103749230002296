import { ClassSegment } from "components/common/Classifications/TagEntity";
import {
  ContractRevenueSchedulePostingMethod,
  CreateInvoiceInput,
  CreateInvoiceLineInput,
  CreateInvoiceLineSchedule,
  InvoiceStatus,
  UpdateInvoiceDiscountLineInput,
  UpdateInvoiceInput,
  UpdateInvoiceLineInput,
  UpdateInvoiceShippingLineInput,
  UpsertClassSegmentInput,
} from "graphql/types";
import { FormLine, FormValues, ScheduleFormValues } from "./types";
import { toScheduleDateRangePayload } from "../shared";
import Big from "big.js";

const toUpsertClassSegmentInput = (formSegment: ClassSegment): UpsertClassSegmentInput => {
  return {
    class: formSegment.reportingClass.name,
    segment: formSegment.name,
    reportingClassType: formSegment.reportingClass.type,
  };
};

const toUpsertClassSegmentInputs = (
  formSegments?: ClassSegment[]
): UpsertClassSegmentInput[] | undefined => {
  return formSegments?.map((f) => toUpsertClassSegmentInput(f));
};

const toCreateInvoiceLineScheduleInput = (
  formSchedule?: ScheduleFormValues
): CreateInvoiceLineSchedule | undefined => {
  return formSchedule
    ? {
        postingMethod: ContractRevenueSchedulePostingMethod.Automatically,
        dateRange: toScheduleDateRangePayload({
          startDate: formSchedule.startDate ?? "",
          serviceDuration: formSchedule.serviceDuration,
        }),
      }
    : undefined;
};

const toCreateInvoiceLineInput = (formLine: FormLine): CreateInvoiceLineInput => {
  return {
    description: formLine.description,
    //if new product don't send id
    productId: formLine.product?.id?.startsWith("product") ? undefined : formLine.product?.id,
    productName: formLine.product?.name,
    coaKey: formLine.category?.coaKey ?? "",
    amount: Big(formLine.amount || 0).toString(),
    schedule: toCreateInvoiceLineScheduleInput(formLine.schedule),
    segments: toUpsertClassSegmentInputs(formLine.segments),
  };
};

const toUpdateInvoiceLineInput = (formLine: FormLine): UpdateInvoiceLineInput => {
  return { id: formLine.id, ...toCreateInvoiceLineInput(formLine) };
};

const toUpdateInvoiceDiscountLineInput = (formLine: FormLine): UpdateInvoiceDiscountLineInput => {
  return {
    id: formLine.id,
    description: formLine.description,
    coaKey: formLine.category?.coaKey ?? "",
    amount: Big(formLine.amount || 0)
      .neg()
      .toString(),
    // todo: RED-572
    // add when supported by GW before launch of editability
    // schedule: toCreateInvoiceLineScheduleInput(formLine.schedule),
    segments: toUpsertClassSegmentInputs(formLine.segments),
  };
};

const toUpdateInvoiceShippingLineInput = (formLine: FormLine): UpdateInvoiceShippingLineInput => {
  return {
    id: formLine.id,
    description: formLine.description,
    coaKey: formLine.category?.coaKey ?? "",
    subtotal: Big(formLine.amount || 0).toString(),
    segments: toUpsertClassSegmentInputs(formLine.segments),
  };
};

const toCreateInvoiceLineInputs = (formLines: FormLine[]): CreateInvoiceLineInput[] =>
  formLines.map((f) => toCreateInvoiceLineInput(f));

const toUpdateInvoiceLineInputs = (formLines: FormLine[]): UpdateInvoiceLineInput[] =>
  formLines.map((f) => toUpdateInvoiceLineInput(f));

const toUpdateInvoiceDiscountLineInputs = (
  formLines: FormLine[]
): UpdateInvoiceDiscountLineInput[] => formLines.map((f) => toUpdateInvoiceDiscountLineInput(f));

const toUpdateInvoiceShippingLineInputs = (
  formLines: FormLine[]
): UpdateInvoiceShippingLineInput[] => formLines.map((f) => toUpdateInvoiceShippingLineInput(f));

export const calculateNumSchedules = (lines: UpdateInvoiceLineInput[]) =>
  lines.reduce((acc, { schedule }) => (schedule ? acc + 1 : acc), 0);

export const toCreateInvoiceInput = (
  companyId: string,
  formData: FormValues,
  isDraft: boolean,
  overrides?: Partial<CreateInvoiceInput>
): CreateInvoiceInput => {
  return {
    companyId,
    description: formData.description,
    dueDate: formData.dueDate,
    issueDate: formData.issueDate,
    customerId: formData.customer?.id,
    externalId: formData.externalId,
    status: isDraft ? InvoiceStatus.Draft : InvoiceStatus.Posted,
    lines: toCreateInvoiceLineInputs(formData.lines),
    ...overrides,
  };
};

export const toUpdateInvoiceInput = (
  invoiceId: string,
  formData: FormValues,
  isDraft: boolean
): UpdateInvoiceInput => {
  return {
    invoiceId,
    description: formData.description,
    dueDate: formData.dueDate,
    issueDate: formData.issueDate,
    customerId: formData.customer?.id,
    externalId: formData.externalId,
    status: isDraft ? InvoiceStatus.Draft : InvoiceStatus.Posted,
    lines: toUpdateInvoiceLineInputs(formData.lines),
    discountLines: toUpdateInvoiceDiscountLineInputs(formData.discountLines),
    shippingLines: toUpdateInvoiceShippingLineInputs(formData.shippingLines),
  };
};
