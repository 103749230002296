import React, { useMemo } from "react";
import { Button } from "@puzzle/ui";
import {
  IngressType,
  IntegrationConnectionCondition,
  IntegrationConnectionStatus,
} from "graphql/types";
import { hasIndeterminateConnection } from "./ListItemUtils";
import InstitutionLogo from "../bank/InstitutionLogo";
import Link from "components/common/Link";

import { Exclamation } from "@puzzle/icons";
import { parseAbsoluteToLocal, useLocalDateFormatter } from "@puzzle/utils";
import useAppRouter from "lib/useAppRouter";
import { ConnectionSubText } from "../DetailDrawer/shared";
import {
  connectionHeaderActionStyle,
  connectionHeaderRecipe,
  connectionIconRecipe,
  connectionTitleInnerStyle,
  connectionTitleStyle,
} from "./connectionCardStyles.css";
import CardIcon from "@mui/material/ListItemIcon";
import { IntegrationConnectionInfo } from "./FinancialInstitutionCard";
import { useActiveCompany } from "components/companies/ActiveCompanyProvider";

interface ConnectionCardHeaderProps {
  financialInstitutionName: string;
  isArchived: boolean;
  showFullContent: boolean;
  connectionInfo?: IntegrationConnectionInfo;
  hasActiveAccounts: boolean;
  onOpenSelectDateChange: (open: boolean) => void;
}

export const ConnectionCardHeader = ({
  financialInstitutionName,
  isArchived,
  showFullContent,
  connectionInfo,
  hasActiveAccounts,
  onOpenSelectDateChange,
}: ConnectionCardHeaderProps) => {
  const { router, isIntegrationRoute, getCurrentRoute } = useAppRouter();
  const { timeZone } = useActiveCompany<true>();
  const dateFormatter = useLocalDateFormatter({ month: "short", day: "numeric", year: "numeric" });
  const timeFormatter = useLocalDateFormatter({ timeStyle: "short" });

  const testIdTitle = `financial-institution-title-${financialInstitutionName}`;

  const showConnectionDetails = (
    integrationConnectionId: string,
    financialInstitutionId?: string
  ) => {
    const integrationPath = ["connection", integrationConnectionId];

    if (financialInstitutionId) {
      integrationPath.push(financialInstitutionId);
    }

    router.push(
      {
        pathname: "/integrations/[[...integrationPath]]",
        query: {
          integrationPath,
        },
      },
      undefined,
      { shallow: isIntegrationRoute(getCurrentRoute()) } // Shallow breaks routing in from inbox tasks
    );
  };

  const connectionSubDetail = useMemo(() => {
    const connectionStatus: IntegrationConnectionStatus | undefined = connectionInfo?.status;

    if (connectionStatus === IntegrationConnectionStatus.Error) {
      return (
        <ConnectionSubText status={hasActiveAccounts ? "warning" : "ok"}>
          <Exclamation /> Temporary outage, no action needed.
        </ConnectionSubText>
      );
    }
    if (connectionStatus === IntegrationConnectionStatus.Disconnected) {
      return (
        <ConnectionSubText status={hasActiveAccounts ? "error" : "ok"}>
          <Exclamation /> Your integration is disconnected.
        </ConnectionSubText>
      );
    }

    if (connectionStatus === IntegrationConnectionStatus.Ok) {
      // Only show this in integrations page, not onboarding
      if (
        showFullContent &&
        connectionInfo?.condition === IntegrationConnectionCondition.WaitingForUserEpoch
      ) {
        return (
          <ConnectionSubText status="error">
            <Link underline onClick={() => onOpenSelectDateChange(true)}>
              <Exclamation /> Set allowable date range to sync
            </Link>
          </ConnectionSubText>
        );
      }

      if (connectionInfo?.lastSyncedAt) {
        const shouldShowWarning = hasIndeterminateConnection(
          {
            lastSyncedAt: connectionInfo.lastSyncedAt,
            ingressType: connectionInfo.ingressType ?? IngressType.Pull,
            status: connectionInfo.status,
          },
          timeZone
        );

        const dateString = `${dateFormatter.format(
          parseAbsoluteToLocal(connectionInfo.lastSyncedAt)
        )} · ${timeFormatter.format(parseAbsoluteToLocal(connectionInfo.lastSyncedAt))}`;

        return (
          <ConnectionSubText status={shouldShowWarning ? "warning" : "ok"}>
            {shouldShowWarning && <Exclamation />} Last synced {dateString}
          </ConnectionSubText>
        );
      }
    }

    return <></>;
  }, [
    connectionInfo,
    hasActiveAccounts,
    timeZone,
    dateFormatter,
    timeFormatter,
    showFullContent,
    onOpenSelectDateChange,
  ]);

  return (
    <div className={connectionHeaderRecipe({ disabled: isArchived })}>
      <div className={connectionTitleStyle}>
        <CardIcon className={connectionIconRecipe({ disabled: isArchived })}>
          <InstitutionLogo institution={{ name: financialInstitutionName }} circular={true} />
        </CardIcon>
        <div className={connectionTitleInnerStyle}>
          <span data-testid={testIdTitle}>{financialInstitutionName}</span>
          {showFullContent && !isArchived && (
            <div className={connectionHeaderActionStyle}>{connectionSubDetail}</div>
          )}
        </div>
      </div>
      {showFullContent && connectionInfo?.integrationConnectionId && !isArchived && (
        <Button
          variant="secondary"
          shape="pill"
          onClick={() => {
            showConnectionDetails(
              connectionInfo.integrationConnectionId ?? "",
              connectionInfo.financialInstitutionId
            );
          }}
        >
          {connectionInfo?.status === IntegrationConnectionStatus.Ok
            ? "Manage integration"
            : "Reconnect"}
        </Button>
      )}
    </div>
  );
};
