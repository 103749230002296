import React, { useMemo, useState, useEffect } from "react";
import { useReactiveVar } from "@apollo/client";
import { styled, Button, Text, CheckboxField, Stack, Alert } from "@puzzle/ui";
import { BankOutline2, PeopleBorderless, CreditCard3 } from "@puzzle/icons";
import { FinancialInstitutionGroup, IntegrationCategory, IntegrationsPage } from "./types";
import { ConnectBankAndCreditCardsModal } from "./modals/ConnectBankAndCreditCardsModal";
import { SuccessfullyAddedManualAccountModal } from "./modals/SuccessfullyAddedManualAccountModal";
import { manualAccountFormValues, manualAccountIdVar } from "./modals/ConnectManualAccountFormStep";
import { ImportTransactionsModal } from "components/dashboard/Transactions/ImportTransactionsModal";
import Link from "components/common/Link";
import { ExternalInline } from "@puzzle/icons";
import FinancialInstitutionCard from "../ListItem/FinancialInstitutionCard";
import {
  getDisplayNameForIntegrationType,
  getIntegrationConnectionForCategory,
  integrationCategoriesWithoutAccounts,
  isIntegrationConnectionMissingAccounts,
} from "./utils";
import Loader from "../../common/Loader";
import { useFinancialInstitutions, usePendingConnections } from "../shared";
import LoadingModal from "../../intro/LoadingModal";
import { ConnectPayrollModal } from "./modals/ConnectPayrollModal";
import { ConnectAccountsPayableModal } from "./modals/ConnectAccountsPayableModal";
import { ConnectRevenueModal } from "./modals/ConnectRevenueModal";
import { ConnectAccountingModal } from "./modals/ConnectAccountingModal";
import { FeatureFlag, isPosthogFeatureFlagEnabled } from "lib/analytics/featureFlags";
import { IntegrationType } from "graphql/types";
import { useRouter } from "next/router";
import GenericConnectionCard from "../ListItem/GenericConnectionCard";
import { RipplingListItemPlaceholder } from "../rippling/RipplingListItemPlaceholder";

const Title = styled("div", {
  textVariant: "$heading2",
  color: "$gray100",
  marginBottom: "$2",
});

const Description = styled("div", {
  textVariant: "$bodyS",
  color: "$gray400",
  marginBottom: "$4",
  display: "flex",
  flexDirection: "column",
  gap: "$0h",
});

const Filters = styled("div", {
  display: "flex",
  marginTop: "$2",
  justifyContent: "right",
});

const Container = styled("div", {
  width: "100%",
  maxWidth: 760,
  margin: "0 auto",
});

const CategoryContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "$3",
});

const FinancialInstitutionCardContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "$1",
});

const SectionHeader = styled("div", {
  display: "flex",
  textVariant: "$headingS",
  color: "$gray500",
  marginBottom: "$3",
  justifyContent: "space-between",

  variants: {
    withSubheader: {
      true: {
        marginBottom: "$0",
      },
      false: {
        marginBottom: "$3",
      },
    },
  },
  defaultVariants: {
    withSubheader: false,
  },
});

const HeaderContent = styled("div", {
  display: "flex",
  gap: "$1",
  flexDirection: "row",
  marginTop: "auto",
  marginBottom: "auto",
});

const Divider = styled("hr", {
  background: "$gray700",
  width: "100%",
  height: "1px",
  border: "none",
  marginTop: "$2",
});

enum IntegrationModal {
  None = "None",
  ConnectBankAndCreditCards = "ConnectBankAndCreditCards",
  ConnectPayroll = "ConnectPayroll",
  ConnectRevenue = "ConnectRevenue",
  ConnectAccountsPayable = "ConnectAccountsPayable",
  ConnectAccounting = "ConnectAccounting",
  SuccessfullyAddedManualAccount = "SuccessfullyAddedManualAccount",
  ImportTransactions = "ImportTransactions",
}

const getIntegrationModalForCategory = (category: IntegrationCategory) => {
  switch (category) {
    case IntegrationCategory.BanksAndCreditCards:
      return IntegrationModal.ConnectBankAndCreditCards;
    case IntegrationCategory.PayrollAndBenefits:
      return IntegrationModal.ConnectPayroll;
    case IntegrationCategory.AccountsPayable:
      return IntegrationModal.ConnectAccountsPayable;
    case IntegrationCategory.Revenue:
      return IntegrationModal.ConnectRevenue;
    case IntegrationCategory.Accounting:
      return IntegrationModal.ConnectAccounting;
    default:
      return IntegrationModal.None;
  }
};

const getTextForCategory = (category: IntegrationCategory, showFullContent: boolean) => {
  switch (category) {
    case IntegrationCategory.BanksAndCreditCards:
      return "Banks & Credit Cards";
    case IntegrationCategory.PayrollAndBenefits:
      return "Payroll & benefits";
    case IntegrationCategory.AccountsPayable:
      return "Accounts Payable & Accounts Receivable";
    case IntegrationCategory.Equity:
      return "Equity";
    case IntegrationCategory.Revenue:
      return showFullContent ? "Revenues" : "Payments";
    case IntegrationCategory.Accounting:
      return "Accounting";
    default:
      return "Other";
  }
};

const getIconForCategory = (category: IntegrationCategory) => {
  switch (category) {
    case IntegrationCategory.BanksAndCreditCards:
      return <BankOutline2 />;
    case IntegrationCategory.PayrollAndBenefits:
      return <PeopleBorderless />;
    case IntegrationCategory.Revenue:
      return <CreditCard3 />;

    default:
      return null;
  }
};

const getSubheaderTextForCategory = (category: IntegrationCategory) => {
  switch (category) {
    case IntegrationCategory.BanksAndCreditCards:
      return "Connecting a bank is required";

    default:
      return null;
  }
};

export const IntegrationPageMainContent = ({
  showFullContent,
  showArchived,
  children,
}: React.PropsWithChildren<unknown> & {
  showFullContent: boolean; // true for the integrations page, false for onboarding
  showArchived: boolean;
}) => {
  const {
    accountsLoading,
    integrationConnectionsLoading,
    integrationConnections,
    refetchAccountsAndIntegrations,
    financialInstitutionGroups,
  } = useFinancialInstitutions();
  const { pendingAccountType, pendingAccountsCount } = usePendingConnections();
  const router = useRouter();
  const isOnboarding = router.pathname.includes("intro");

  // Refetch so connections appear after they are added
  useEffect(() => {
    refetchAccountsAndIntegrations();
  }, [pendingAccountsCount, refetchAccountsAndIntegrations]);

  const [currentOpenModal, setCurrentOpenModal] = useState<IntegrationModal>(IntegrationModal.None);
  const isRipplingIntegrationConnectionEnabled = isPosthogFeatureFlagEnabled(
    FeatureFlag.RipplingIntegrationConnection
  );
  const loading = accountsLoading || integrationConnectionsLoading;

  const onOpenChange = (type: IntegrationModal) => (open: boolean) => {
    if (open) {
      setCurrentOpenModal(type);
    } else {
      setCurrentOpenModal(IntegrationModal.None);
    }
  };

  const showRipplingOnboardingPlaceholder = (category: IntegrationCategory) => {
    // TODO: add condition to show only after users try to connect
    if (
      category === IntegrationCategory.PayrollAndBenefits &&
      isOnboarding &&
      isRipplingIntegrationConnectionEnabled
    ) {
      <Stack>
        <GenericConnectionCard financialInstitutionName={IntegrationType.Rippling} />
        <Alert kind="default">
          Connect to your Rippling account after you've created your account. Don't worry, we'll
          remind you.
        </Alert>
      </Stack>;
    }
    return null;
  };

  const showRipplingIntegrationPlaceholder = (category: IntegrationCategory) => {
    if (
      isRipplingIntegrationConnectionEnabled &&
      category === IntegrationCategory.PayrollAndBenefits &&
      !isOnboarding
    ) {
      return true;
    }
    return false;
  };

  const manualAccountId = useReactiveVar(manualAccountIdVar);

  const sortedAndFilteredCategories = useMemo(() => {
    const integrationCategoriesBase = [
      IntegrationCategory.BanksAndCreditCards,
      IntegrationCategory.PayrollAndBenefits,
      IntegrationCategory.Revenue,
    ];
    const integrationCategoriesAdditional = [
      IntegrationCategory.AccountsPayable,
      IntegrationCategory.Accounting,
    ];

    const staticIntegrationCategories = showFullContent
      ? [...integrationCategoriesBase, ...integrationCategoriesAdditional]
      : integrationCategoriesBase;

    const results = staticIntegrationCategories.map((category) => {
      const financialInstitutions = financialInstitutionGroups.get(category) || [];

      if (financialInstitutions.length > 0) {
        // Filter out any archived accounts and hide the entire financial institution if there are no accounts left
        if (showArchived === false) {
          const filteredFinancialInstitutions: FinancialInstitutionGroup[] = [];
          financialInstitutions.forEach((fi) => {
            const accounts = fi.accounts.filter((account) => !account.archivedAt);
            if (accounts.length > 0) {
              filteredFinancialInstitutions.push({ ...fi, accounts });
            }
          });
          return { category, financialInstitutions: filteredFinancialInstitutions };
        } else {
          return { category, financialInstitutions };
        }
      }

      // Check if there are any integration connections that don't have any accounts (and shouldn't)
      if (integrationCategoriesWithoutAccounts.includes(category)) {
        const connections = getIntegrationConnectionForCategory(category, integrationConnections);
        for (const connection of connections) {
          financialInstitutions.push({
            financialInstitutionName: getDisplayNameForIntegrationType(connection.type),
            accounts: [],
            integrationConnections: [connection],
          });
        }
      }

      return {
        category,
        financialInstitutions,
      };
    });

    // If there are any integration connections that don't have any accounts, but should
    // Append the "Other" category and include them there
    const connectionsWithoutAccounts = integrationConnections.filter((connection) =>
      isIntegrationConnectionMissingAccounts(connection)
    );

    if (connectionsWithoutAccounts.length > 0) {
      const financialInstitutions: FinancialInstitutionGroup[] = [];
      for (const connection of connectionsWithoutAccounts) {
        financialInstitutions.push({
          financialInstitutionName: getDisplayNameForIntegrationType(connection.type),
          accounts: [],
          integrationConnections: [connection],
        });
      }

      results.push({ category: IntegrationCategory.Other, financialInstitutions });
    }

    return results;
  }, [financialInstitutionGroups, integrationConnections, showArchived, showFullContent]);

  return (
    <CategoryContainer>
      {loading && <Loader />}

      {!loading &&
        sortedAndFilteredCategories.map(({ category, financialInstitutions }) => (
          <div key={category} data-testid={`category-section-${category}`}>
            <SectionHeader
              withSubheader={!showFullContent && !!getSubheaderTextForCategory(category)}
            >
              <HeaderContent>
                {!showFullContent && getIconForCategory(category)}
                <Text variant="headingM" weight="bold" color="gray200">
                  {getTextForCategory(category, showFullContent)}
                </Text>
              </HeaderContent>

              {getIntegrationModalForCategory(category) !== IntegrationModal.None && (
                <HeaderContent>
                  <Button
                    variant="secondary"
                    shape="pill"
                    onClick={() => {
                      const modalType = getIntegrationModalForCategory(category);
                      manualAccountFormValues({});
                      setCurrentOpenModal(modalType);
                    }}
                  >
                    {showFullContent ? "Add integration" : "Connect"}
                  </Button>
                </HeaderContent>
              )}
            </SectionHeader>

            {!showFullContent && getSubheaderTextForCategory(category) && (
              <SectionHeader>
                <Text variant="headingS" weight="normal" color="gray300">
                  {getSubheaderTextForCategory(category)}
                </Text>
              </SectionHeader>
            )}

            {financialInstitutions.length > 0 ? (
              <FinancialInstitutionCardContainer>
                {financialInstitutions.map((fi) => {
                  return (
                    <FinancialInstitutionCard
                      key={`${fi.financialInstitutionName}-${category}`}
                      financialInstitution={fi}
                      integrationCategory={category}
                      showFullContent={showFullContent}
                    />
                  );
                })}
                {showRipplingOnboardingPlaceholder(category)}
                {showRipplingIntegrationPlaceholder(category) && <RipplingListItemPlaceholder />}
              </FinancialInstitutionCardContainer>
            ) : (
              <>
                {showRipplingOnboardingPlaceholder(category)}
                {showRipplingIntegrationPlaceholder(category) && <RipplingListItemPlaceholder />}
                <Divider />
              </>
            )}
          </div>
        ))}

      {children}

      {/* Only one of the following should be open at a time. */}
      <ConnectBankAndCreditCardsModal
        open={currentOpenModal === IntegrationModal.ConnectBankAndCreditCards}
        onOpenChange={onOpenChange(IntegrationModal.ConnectBankAndCreditCards)}
        onSave={async () => {
          try {
            setCurrentOpenModal(IntegrationModal.None);
            refetchAccountsAndIntegrations();
          } catch (err) {
            console.error(err);
          }
        }}
      />
      <ConnectPayrollModal
        open={currentOpenModal === IntegrationModal.ConnectPayroll}
        onOpenChange={onOpenChange(IntegrationModal.ConnectPayroll)}
      />
      <ConnectAccountsPayableModal
        open={currentOpenModal === IntegrationModal.ConnectAccountsPayable}
        onOpenChange={onOpenChange(IntegrationModal.ConnectAccountsPayable)}
      />
      <ConnectRevenueModal
        open={currentOpenModal === IntegrationModal.ConnectRevenue}
        onOpenChange={onOpenChange(IntegrationModal.ConnectRevenue)}
      />
      <ConnectAccountingModal
        open={currentOpenModal === IntegrationModal.ConnectAccounting}
        onOpenChange={onOpenChange(IntegrationModal.ConnectAccounting)}
      />
      <SuccessfullyAddedManualAccountModal
        open={currentOpenModal === IntegrationModal.SuccessfullyAddedManualAccount}
        onOpenChange={onOpenChange(IntegrationModal.SuccessfullyAddedManualAccount)}
        onPrimary={() => {
          setCurrentOpenModal(IntegrationModal.ImportTransactions);
        }}
        onSecondary={() => {
          manualAccountIdVar(undefined);
        }}
      />
      <ImportTransactionsModal
        open={currentOpenModal === IntegrationModal.ImportTransactions}
        onOpenChange={onOpenChange(IntegrationModal.ImportTransactions)}
        initialAccountId={manualAccountId}
      />
      <LoadingModal accountType={pendingAccountType} open={!!pendingAccountType} />
    </CategoryContainer>
  );
};

const IntegrationsMainPage: IntegrationsPage = ({ children }: React.PropsWithChildren<unknown>) => {
  const [showArchived, setShowArchived] = useState<boolean>(false);

  return (
    <Container>
      <Title>Integrations</Title>
      <Description>
        <div>
          Connect your data sources to get the most out of Puzzle. Puzzle does not see or store any
          credentials.{" "}
          <Link
            href="https://help.puzzle.io/en/articles/7061148-faqs"
            target="_blank"
            color="green600"
            css={{ display: "inline-flex", alignItems: "center", gap: 2, lineHeight: "24px" }}
          >
            Learn more <ExternalInline />
          </Link>
        </div>
        <Filters>
          <CheckboxField
            label={<Text color="gray300">Show archived</Text>}
            checked={showArchived}
            onCheckedChange={() => setShowArchived(!showArchived)}
          />
        </Filters>
      </Description>

      <IntegrationPageMainContent showFullContent={true} showArchived={showArchived}>
        {children}
      </IntegrationPageMainContent>
    </Container>
  );
};

export default IntegrationsMainPage;
