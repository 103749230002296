import React, { useMemo, useRef, useState, useEffect } from "react";

import {
  MRT_TableOptions,
  MRT_RowData,
  MRT_TableInstance,
  MaterialReactTable,
} from "material-react-table";
import { ThemeProvider } from "@mui/material/styles";

import { tableSpecificThemes, dataGridTheme } from "./DataGridTheme";
import { useMaterialReactTable } from "material-react-table";
import { formatMrtCol } from "./dataGridUtils";
import { RootTableStyle } from "./DataGrid.css";
import useResizeObserver from "use-resize-observer";
import { useDebouncedCallback } from "use-debounce";
import { MutableRefObject } from "react";

interface DataGridProps<RowData extends MRT_RowData> {
  options: MRT_TableOptions<RowData>;
  height?: string;
  tableRef?: MutableRefObject<MRT_TableInstance<RowData> | null>;
  emptyState?: React.ReactElement;
}

const defaultOptions = {
  columns: [],
  data: [],
  enableTopToolbar: false,
  enableBottomToolbar: false,
  enableSorting: false,
  enableColumnActions: false,
  enableColumnFilters: false,
  enablePagination: false,
  enableColumnVirtualization: false,
  enableStickyHeader: false,
  enableColumnOrdering: false,
};

/**
 * Latest DataTable component built off Material React Table
 * @param options - See Material React Table options
 *
 * @param height - Height of the table, defaults to 100%
 */
const DataGrid = <RowData extends MRT_RowData>({
  options,
  height,
  tableRef,
  emptyState,
}: DataGridProps<RowData>) => {
  const col = useMemo(() => formatMrtCol(options.columns), [options.columns]);

  const tableContainerRef = useRef<HTMLDivElement>(null);
  const [tableContainerHeight, setTableContainerHeight] = useState(
    `${tableContainerRef.current?.clientHeight.toString()}px`
  );

  const debouncedSetTableContainerHeight = useDebouncedCallback((input) => {
    setTableContainerHeight(input);
  }, 500);

  useResizeObserver({
    ref: tableContainerRef,
    onResize: ({ height }) => {
      if (height) {
        debouncedSetTableContainerHeight(`${height}px`);
      }
    },
  });

  const table = useMaterialReactTable({
    ...defaultOptions,
    ...tableSpecificThemes,
    ...options,
    muiTableContainerProps: {
      sx: { maxHeight: height || tableContainerHeight },
      ...options.muiTableContainerProps, // overrides that are passed down
    },

    muiTableHeadCellProps: {
      // This gives all tables the style where action icons are hidden until hovered over
      // Except for the "Select All" checkbox in the header
      sx: (theme) => ({
        ".Mui-TableHeadCell-Content": {
          color: theme.palette.text.secondary,
          svg: {
            // hide the SVG icons in the header until the header is hovered over
            // Hiding the icons in this way still allows the icons to be read by screen readers
            color: theme.palette.background.default,
            fill: theme.palette.background.default,
          },
          // on hover: highlight the text and show the SVG icons
          "&:hover": {
            ".Mui-TableHeadCell-Content-Wrapper": {
              color: theme.palette.text.primary,
            },
            svg: {
              color: theme.palette.text.primary,
              fill: theme.palette.text.primary,
            },
          },
          // if a checkbox icon is in the header, always show it (no hover required)
          // For the "Select All" checkbox in table header
          ".MuiCheckbox-root": {
            svg: {
              color: theme.palette.text.primary,
              fill: theme.palette.text.primary,
            },
          },
        },
      }),
      ...options.muiTableHeadCellProps, // overrides that are passed down
    },

    columns: col,
    renderEmptyRowsFallback: () =>
      emptyState ?? <div style={{ padding: "10px" }}>No results found.</div>,
  });

  useEffect(() => {
    if (tableRef) {
      tableRef.current = table;
    }
  }, [table, tableRef]);

  return (
    <div ref={tableContainerRef} className={RootTableStyle}>
      <ThemeProvider theme={dataGridTheme}>
        <MaterialReactTable table={table} />
      </ThemeProvider>
    </div>
  );
};

export default DataGrid;
