import React, { useMemo } from "react";
import { styled } from "@puzzle/ui";
import { EmptyPane } from "./EmptyPane";
import { useInboxContext } from "../InboxContext";
import { TransactionPane } from "./TransactionPane";
import { OpeningBalancePane } from "./OpeningBalancePane";
import {
  AdditionalServicesFeedItemData,
  CompanyOnboardChecklistTasktype,
  DirectIngestFeedItemData,
  IntegrationFeedItemData,
  OpeningBalanceFeedItemData,
  TaskType,
  TransactionFeedItemData,
  UncategorizedTransactionsData,
  UpdateType,
  UserAddedFeedItemData,
  UserRemovedFeedItemData,
} from "../shared";
import { IntegrationPane } from "./IntegrationPane";
import { DirectIngestPane } from "./DirectIngestPane";
import { UserPane } from "./UserPane";
import { ErrorPane } from "./ErrorPane";
import { FreeTrialExpiringPane } from "./FreeTrialExpiringPane";
import { FreeTrialExpiredPane } from "./FreeTrialExpiredPane";
import { CompanyOnboardChecklistPane } from "./CompanyOnboardChecklistPane";
import { FeedItem } from "@knocklabs/client";
import { UncategorizedTransactionsPane } from "./UncategorizedTransactionsPane";
import TransactionsPageProvider from "components/dashboard/Transactions/TransactionsProvider";
import { AdditionalServicesPane } from "./AdditionalServicesPane";
import { MonthlyExpensesExceededPane } from "./MonthlyExpensesExceededPane";
import { ProposeStartIngestionDatePane } from "./ProposeStartIngestionDatePane";
import { HistoricalBooksStepCompletePane } from "./HistoricalBooksStepCompletePane";
import { ConnectRipplingPane } from "./Rippling";

export const Pane = styled("div", {
  display: "flex",
  width: "100%",
  height: "100vh",
  overflow: "hidden",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
});

export const DetailPane = () => {
  const { activeItem } = useInboxContext();

  const content = useMemo(() => {
    if (!activeItem) {
      return <EmptyPane />;
    }

    if (activeItem) {
      // There was a time we didn't attach this messageType..
      if (activeItem.source.key === "opening-balance") {
        return <OpeningBalancePane item={activeItem as FeedItem<OpeningBalanceFeedItemData>} />;
      }

      // TODO FeedItem and item.data.messageType don't work well with type narrowing
      switch (activeItem.data?.messageType) {
        case TaskType.OpeningBalance:
          return <OpeningBalancePane item={activeItem as FeedItem<OpeningBalanceFeedItemData>} />;
        case TaskType.AdditionalServices:
          return (
            <AdditionalServicesPane item={activeItem as FeedItem<AdditionalServicesFeedItemData>} />
          );
        case TaskType.Integration:
        case TaskType.IntegrationWarning:
          return <IntegrationPane item={activeItem as FeedItem<IntegrationFeedItemData>} />;

        case UpdateType.UserAdded:
        case UpdateType.UserRemoved:
          return (
            <UserPane
              item={activeItem as FeedItem<UserAddedFeedItemData | UserRemovedFeedItemData>}
            />
          );

        case UpdateType.DirectIngestSucceeded:
          return <DirectIngestPane item={activeItem as FeedItem<DirectIngestFeedItemData>} />;

        case TaskType.Transaction:
        case UpdateType.TransactionSubscribe:
          return (
            <TransactionsPageProvider>
              <TransactionPane
                item={activeItem as FeedItem<TransactionFeedItemData>}
                id={(activeItem as FeedItem<TransactionFeedItemData>).data!.transactionId!}
                commentBoxMode="messaging"
              />
            </TransactionsPageProvider>
          );
        // this will act the same ask the other categorization requests for now.
        // TODO differently
        case TaskType.TransactionAI:
        case `transactionAI`:
          return (
            <TransactionsPageProvider>
              <TransactionPane
                item={activeItem as FeedItem<TransactionFeedItemData>}
                id={(activeItem as FeedItem<TransactionFeedItemData>).data!.transactionId!}
                commentBoxMode="provide_ai_context"
              />
            </TransactionsPageProvider>
          );
        case TaskType.UncategorizedTransactions:
          return (
            <UncategorizedTransactionsPane
              item={activeItem as FeedItem<UncategorizedTransactionsData>}
            />
          );
        case TaskType.CompanyOnboardChecklist:
          /* blocks[1].content comes from the action_url in the template. This is used as a work-around
             to handle the fact that the workflow dispatches four different inbox items. */
          return (
            <CompanyOnboardChecklistPane
              item={activeItem as FeedItem}
              checklistTaskType={
                activeItem?.blocks?.[1]?.content as CompanyOnboardChecklistTasktype
              }
            />
          );
        case TaskType.FreeTrialExpiring7days:
          return <FreeTrialExpiringPane item={activeItem as FeedItem} />;
        case TaskType.FreeTrialExpired:
          return <FreeTrialExpiredPane item={activeItem as FeedItem} />;
        case TaskType.MonthlySpendingExceeded:
          return <MonthlyExpensesExceededPane />;
        case TaskType.StartIngestionDate:
          return <ProposeStartIngestionDatePane item={activeItem as FeedItem} />;
        case TaskType.ConnectRippling:
          return <ConnectRipplingPane />;
        case UpdateType.HistoricalBooksInputComplete:
          return (
            <HistoricalBooksStepCompletePane
              bodyText="You have completed Step 1 of the historical books transfer process! The information you
          gathered has been shared with the Puzzle team, who will complete Step 2. This usually
          takes 5-10 business days. Upon completion of the next step, you will receive a
          notification with further instructions."
              linkText="View details"
            />
          );
        case UpdateType.HistoricalBooksImportComplete:
          return (
            <HistoricalBooksStepCompletePane
              bodyText="Your historical financials have been imported! To review the outputs, follow the steps in the historical books checklist."
              linkText="Review now"
            />
          );
        case UpdateType.HistoricalBooksReviewComplete:
          return (
            <HistoricalBooksStepCompletePane
              linkText="Review details"
              bodyText="Congratulations! Your conversion to Puzzle is complete. Enjoy your upgrade to a modern accounting system!"
            />
          );
        case UpdateType.HistoricalBooksIncomplete:
          return (
            <HistoricalBooksStepCompletePane
              linkText="View details"
              bodyText={`${
                !activeItem.data.stepNumber ? "A step" : `Step ${activeItem.data.stepNumber}`
              } of your historical books checklist is no longer complete since one or more items was marked incomplete. Additional steps are required.`}
            />
          );
      }
    }

    return <ErrorPane />;
  }, [activeItem]);

  return <Pane>{content}</Pane>;
};
