import React, { useState, useRef } from "react";
import Tag from "./Tag";
import {
  hoverCardText,
  hoverCardBody,
  tagFan as tagFanStyle,
  hoverCardLabel,
} from "./tagFanStyles.css";
import HoverCard from "components/common/HoverCard";
import { zIndex } from "ve";

export type Tag = {
  id: string;
  name: string;
  group: string;
  hoveredIdx: number | null;
  maxWidth: number;
  width: "auto" | number;
  originalWidth: number | null;
  transition: string;
};

type TagFanProps = {
  tags: Tag[];
  outerDivSize: number;
};

/*

  This component presents a fan of tags to the user that expand when hovered.
  First, it calculates the max width of each tag
    based on the number of tags and the width of the containing div.
  So a tag will either be a width that holds all its text,
    or it will hit the max width and be truncated.

  When a tag is hovered, it expands an amount to show more of its text,
    while shrinking the other tags to the MIN_TAG_WIDTH.
  The calculation of how much to expand is based on
   - the number of tags
   - the width of the containing div.
   - and whether or not the tag is the top tag.
  See the getMaxWidth function for the exact calculations.

  Because these tags can shift in size when hovered,
    we prevent the outer div from shifting by creating an empty div that is the width of all the tags.

  We don't grab the actual width of all the tags in a div until the user hovers over a tag.
  Then we save the total width of all the tags, and set the width of the empty div to that total width.  

  Full issue and conversation here:
  https://linear.app/puzzlefin/issue/GRO-1823/[fe]-change-classifications-from-displaying-a-count-to-displaying-the
  
*/

const COVERUP_AMT = 16;
const MIN_TAG_WIDTH = 48;

export const TagFan = ({ tags, outerDivSize }: TagFanProps) => {
  const [tagsWithState, setTagsWithState] = useState<Tag[]>(tags);
  const [tagGroupWidth, setTagGroupWidth] = useState<number | null>(null);
  const tagGroupRef = useRef<HTMLDivElement>(null);

  const numOfTags = tags.length;

  const getMaxWidth = (idxOfHoveredTag: number, idxOfThisTag: number, tagGroupWidth: number) => {
    const numOfOtherTags = numOfTags - 1;
    const otherTagsWidth = MIN_TAG_WIDTH * numOfOtherTags;
    const coverupAmt = COVERUP_AMT * numOfOtherTags;

    // if this tag IS HOVERED and IS TOP tag
    if (idxOfHoveredTag === idxOfThisTag && idxOfThisTag === 0) {
      const widthAmount = tagGroupWidth + coverupAmt - otherTagsWidth;
      return widthAmount;
    }

    // if this tag IS hovered and is NOT top tag
    else if (idxOfHoveredTag === idxOfThisTag && idxOfThisTag !== 0) {
      return tagGroupWidth + coverupAmt - otherTagsWidth;
    }

    // if this tag is NOT hovered, but a sibling IS
    else if (idxOfHoveredTag !== idxOfThisTag && idxOfHoveredTag !== null) {
      return MIN_TAG_WIDTH;
    } else return Math.floor(tagGroupWidth / numOfTags);
  };

  const handleMouseEnter = (idxOfHoveredTag: number) => {
    if (tags.length > 1) {
      if (!tagGroupWidth) {
        // Add up all the widths of all the rendered children (tags) to get the total
        const tagElements = tagGroupRef.current?.children;
        let totalWidth = 0;
        let originalWidths: number[] = [];
        if (tagElements) {
          // Iterate over the child elements to get their widths
          Array.from(tagElements).forEach((tag, i) => {
            const tagWidth = (tag as HTMLElement).getBoundingClientRect().width;
            // Save the original width of each tag
            if (i !== 0) {
              originalWidths.push(tagWidth + COVERUP_AMT);
            } else {
              originalWidths.push(tagWidth);
            }
            // add each width to the total
            totalWidth += tagWidth;
          });

          setTagGroupWidth(totalWidth);
          setTagsWithState((prevTagsWithState) => {
            return prevTagsWithState.map((tag, i) => {
              const newWidth = getMaxWidth(idxOfHoveredTag, i, totalWidth);
              return {
                ...tag,
                originalWidth: originalWidths[i],
                width: newWidth,
                maxWidth: newWidth,
                transition: "width 0.3s, max-width 0.3s",
              };
            });
          });
        }
      } else {
        setTagsWithState((prevTagsWithState) => {
          return prevTagsWithState.map((tag, idxOfThisTag) => {
            const newWidth = getMaxWidth(idxOfHoveredTag, idxOfThisTag, tagGroupWidth);
            return {
              ...tag,
              hoveredIdx: idxOfHoveredTag,
              width: newWidth,
              maxWidth: newWidth,
              transition: "width 0.3s, max-width 0.3s",
            };
          });
        });
      }
    }
  };

  const handleMouseLeave = () => {
    if (tags.length > 1) {
      // update tagsWithState to reset to original widths
      setTagsWithState((prevTagsWithState) => {
        return prevTagsWithState.map((tag) => {
          return {
            ...tag,
            width: tag.originalWidth || MIN_TAG_WIDTH,
            maxWidth: tag.originalWidth || MIN_TAG_WIDTH,
            hoveredIdx: null,
            transition: "width 0.3s, max-width 0.3s",
          };
        });
      });
    }
  };

  return (
    <div style={{ minWidth: outerDivSize }}>
      <div className={tagFanStyle} ref={tagGroupRef}>
        {tagsWithState.map((tag: Tag, i: number) => (
          <HoverCard
            key={tag.id}
            side="top"
            arrow={true}
            openDelay={350}
            closeDelay={100}
            style={{ zIndex: zIndex("tooltip") }}
            trigger={
              <div>
                <Tag
                  i={i}
                  tag={tag}
                  coverupAmt={COVERUP_AMT}
                  handleMouseEnter={handleMouseEnter}
                  handleMouseLeave={handleMouseLeave}
                />
              </div>
            }
          >
            <HoverCard.Body className={hoverCardBody}>
              <p className={hoverCardText}>
                <span className={hoverCardLabel}>{tag.group}:</span>
                <span>{tag.name}</span>
              </p>
            </HoverCard.Body>
          </HoverCard>
        ))}
      </div>
      {/* 
        This empty div is to hold the width of all the tags when the component mounts.
        So the column doesn't shift sizes when tags are hovered and their sizes change.
        
        For debugging purposes, to see the intended tagGroupWidth, uncomment the height and backgroundColor
      */}
      <div
        style={{
          display: "block",
          float: "left",
          width: tagGroupWidth || 0,
          // height: 2,
          // backgroundColor: "red",
        }}
      />
    </div>
  );
};
