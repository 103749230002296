import React, { useCallback, useMemo, useRef } from "react";
import { FeedItem, GenericData, Recipient } from "@knocklabs/client";
import * as NavigationMenu from "@radix-ui/react-navigation-menu";
import { parseAbsoluteToLocal, useLocalDateFormatter } from "@puzzle/utils";
import { shallow } from "zustand/shallow";

import { styled, colors, Text, Avatar, Button, Stack, Menu, StatusIndicator } from "@puzzle/ui";
import {
  BookkeepingService,
  Calendar,
  CheckList,
  Ellipsis,
  Exclamation,
  NavGetStarted,
  PlugOutline,
} from "@puzzle/icons";

import {
  InboxFeed,
  InboxFeedItem,
  TaskType,
  UpdateType,
  CompanyOnboardChecklistTasktype,
} from "./shared";
import { useInboxContext, useInboxStore } from "./InboxContext";
import { AccountingIcon, TransactionIcon } from "./icons";
import { Route } from "lib/routes";
import Link from "next/link";
import Loader from "components/common/Loader";
import Analytics from "lib/analytics";
import { differenceInHours } from "date-fns";
import { ActiveCompanyFragment, useActiveCompany } from "components/companies";
import { CompanyOrgType } from "graphql/types";
import { Box, vars } from "ve";

const Root = styled("div", {
  display: "flex",
  flexDirection: "column",
  width: "100%",
});

const ItemRoot = styled(NavigationMenu.Item, {
  padding: "$1h 0",

  "&:not(:last-child)": {
    borderBottom: "1px solid #303040",
  },
});

const ItemToolbar = styled("div", {
  display: "flex",
  flexDirection: "column",
  justifyContent: "start",
  alignSelf: "start",
  alignItems: "flex-end",
  gap: "$0h",
  marginLeft: "$1h",
});

const ItemIndicators = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "$0h",
  transform: "translateX(-6px)",
});

const Body = styled("div", {
  color: "$gray300",
  textVariant: "$bodyXS",
});

const Timestamp = styled("div", {
  color: "$gray400",
  textVariant: "$bodyXS",
  marginTop: "4px",
});

const ItemIcon = styled("div", {
  marginRight: "$3",
});

const Detail = styled("div", {
  flex: 1,

  "& p": {
    margin: 0,
  },
});

const ItemBox = styled("div", {
  padding: "$1h $2",
  borderRadius: "$1",

  position: "relative",
  display: "flex",
  cursor: "pointer",
  alignItems: "center",
  borderBottom: "1px solid $mauve680",

  "&:last-child": {
    borderBottom: 0,
  },

  "&:hover, &:focus, &:focus-within": {
    outline: "none",
    backgroundColor: "$rhino700",
    borderRadius: "$1",
  },

  defaultVariants: {
    status: "unseen",
  },

  "&[data-active]": {
    background: "#2F2E47 !important",
  },

  variants: {
    status: {
      unseen: {},
      read: {},
      archived: {},
      seen: {},
    },
  },
});

const CircledIcon = styled("div", {
  width: "28px",
  height: "28px",
  backgroundColor: "$purple900",
  borderRadius: "$ellipse",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const LabelContainer = styled(ItemRoot, {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "$1h",
});

const getStatus = (item: FeedItem) => {
  if (item.archived_at) {
    return "archived";
  } else if (item.read_at) {
    return "read";
  } else if (item.seen_at) {
    return "seen";
  }
  return "unseen";
};

const indexForChecklistTaskType = (
  type: CompanyOnboardChecklistTasktype,
  company: ActiveCompanyFragment
): string => {
  const order = [
    CompanyOnboardChecklistTasktype.ConnectAccounts,
    CompanyOnboardChecklistTasktype.CategorizeTopTransactions,
    CompanyOnboardChecklistTasktype.BookOnboardingCall,
    CompanyOnboardChecklistTasktype.InviteTeam,
    CompanyOnboardChecklistTasktype.BookkeepingTaxPrep,
  ];
  const index = order
    // We don't want to show BookOnboardingCall if orgType is anything other than
    // CompanyOrgType.CCorporation or null to protect customer support's time from non-ICPs
    .filter((item) =>
      company.orgType !== CompanyOrgType.CCorporation &&
      company.orgType !== null &&
      item === CompanyOnboardChecklistTasktype.BookOnboardingCall
        ? false
        : true
    )
    .indexOf(type);
  if (index === -1) {
    return "?";
  }
  return `${index + 1}`;
};

export function Item<T extends InboxFeedItem = InboxFeedItem>({
  item,
  onClick,
  icon: _icon,
}: {
  item: T;
  onClick?: () => void;
  icon?: React.ReactElement | null;
}) {
  const { company } = useActiveCompany<true>();
  const { feed, feedClient, activeItem, setActiveItem, handleArchivalClick } = useInboxContext();
  const actor = item.actors?.[0] as Recipient | undefined;

  const dateFormatter = useLocalDateFormatter({ month: "short", day: "numeric" });
  const timeFormatter = useLocalDateFormatter({ timeStyle: "short" });
  const insertedAtDate = parseAbsoluteToLocal(item.inserted_at);

  // Leaving this for future refactoring, we could need to track tab changes.
  // useEffect(() => {
  //   if (feed) {
  //     console.log("Inbox viewed", feed);
  //     Analytics.inboxViewed({ tab: feed });
  //   }
  // }, [feed]);

  const messageType =
    item.data && "messageType" in item.data
      ? (item as unknown as InboxFeedItem).data?.messageType
      : null;

  const taskSubType =
    item.data && "taskSubType" in item.data
      ? (item as unknown as GenericData).data?.taskSubType
      : null;

  const handleClick = useCallback(() => {
    // Why is source.key being used here??
    const source = messageType && taskSubType ? `${messageType}__${taskSubType}` : item.source.key;

    Analytics.inboxItemViewed({
      status: getStatus(item),
      id: item.id,
      source,
      messageType: item.data?.messageType,
      tab: "tasks",
      createdHoursAgo: differenceInHours(new Date(), new Date(item.inserted_at)),
    });
    setActiveItem(item);
    if (!item.seen_at) {
      feedClient?.markAsSeen(item);
      Analytics.inboxItemStatusChanged({ id: item.id, action: "seen" });
    }
    onClick?.();
  }, [feedClient, item, onClick, setActiveItem, messageType, taskSubType]);

  const actionOptions = useMemo(() => {
    if (messageType === TaskType.Integration || messageType === TaskType.IntegrationWarning) {
      return {
        text: "Go to Integrations",
        href: Route.integrations,
      };
    } else if (messageType === TaskType.Transaction) {
      return {
        text: "Go to Transactions",
        href: Route.transactions,
      };
    }

    return null;
  }, [messageType]);

  const icon = useMemo(() => {
    if (_icon) {
      return _icon;
    }

    if (
      messageType === TaskType.Integration ||
      messageType === TaskType.IntegrationWarning ||
      messageType === TaskType.ConnectRippling
    ) {
      return (
        <CircledIcon style={{ backgroundColor: vars.colors.elephant300 }}>
          <PlugOutline/>
        </CircledIcon>
      );
    } else if (
      messageType === TaskType.Transaction ||
      messageType === UpdateType.DirectIngestSucceeded
    ) {
      return <TransactionIcon />;
    } else if (messageType === TaskType.OpeningBalance || item.source.key === "opening-balance") {
      return (
        <CircledIcon>
          <CheckList size={11} />
        </CircledIcon>
      );
    } else if (messageType === TaskType.CompanyOnboardChecklist) {
      return (
        <CircledIcon>
          {indexForChecklistTaskType(
            item?.blocks?.[1]?.content as CompanyOnboardChecklistTasktype,
            company
          )}
        </CircledIcon>
      );
    } else if (messageType === TaskType.CreditCardAccuracy) {
      return <AccountingIcon />;
    } else if (messageType === TaskType.StartIngestionDate) {
      return <Calendar size={28} />;
    } else if (
      [
        UpdateType.HistoricalBooksInputComplete,
        UpdateType.HistoricalBooksImportComplete,
        UpdateType.HistoricalBooksReviewComplete,
        UpdateType.HistoricalBooksIncomplete,
      ].includes(messageType)
    ) {
      return <BookkeepingService size={28} />;
    }

    const name =
      item?.data && "userName" in item.data
        ? item.data.userName
        : actor && "name" in actor
        ? actor.name
        : "?";

    if (name) {
      return (
        <Avatar
          size="small"
          user={{
            email: actor && "email" in actor ? actor.email : undefined,
            name,
          }}
        />
      );
    } else {
      return <Exclamation fill={colors.red500} width={28} height={28} />;
    }
  }, [_icon, actor, item.blocks, item.data, item.source.key, messageType, company]);

  // We don't want to show BookOnboardingCall if orgType is anything other than
  // CompanyOrgType.CCorporation or null to protect customer support's time from non-ICPs
  if (
    item?.blocks?.[1]?.content === CompanyOnboardChecklistTasktype.BookOnboardingCall &&
    company.orgType !== CompanyOrgType.CCorporation &&
    company.orgType !== null
  ) {
    return null;
  }

  return (
    <ItemRoot value={item.id}>
      <NavigationMenu.Link active={activeItem?.id === item.id} asChild onSelect={handleClick}>
        <ItemBox
          tabIndex={0}
          onKeyDown={(e) => {
            if (activeItem?.id !== item.id && [" ", "Enter"].includes(e.key)) {
              handleClick();
            }
          }}
          status={getStatus(item)}
        >
          <ItemIcon>{icon}</ItemIcon>

          <Detail>
            <Body
              dangerouslySetInnerHTML={{
                __html: item.blocks?.[0].rendered,
              }}
            />

            <Timestamp>
              {dateFormatter.format(insertedAtDate)} at {timeFormatter.format(insertedAtDate)}
            </Timestamp>

            {actionOptions && (
              <Link href={actionOptions.href} passHref>
                <Button size="mini" variant="secondary" css={{ marginTop: "$1h" }}>
                  {actionOptions.text}
                </Button>
              </Link>
            )}
          </Detail>

          <ItemToolbar>
            <Menu
              trigger={
                <Button
                  size="mini"
                  variant="minimal"
                  css={{
                    opacity: 0.2,
                    padding: "6px",
                    height: "auto",

                    '&[data-state="open"], &:focus, &:hover': {
                      opacity: "1",
                    },

                    "*": {
                      lineHeight: 0,
                    },
                  }}
                >
                  <Ellipsis size={14} />
                </Button>
              }
            >
              <Menu.Group>
                <Menu.Item
                  onClick={async (e) => {
                    e.stopPropagation();
                    if (item.seen_at) {
                      await feedClient?.markAsUnseen(item);
                      Analytics.inboxItemStatusChanged({
                        id: item.id,
                        action: "unseen",
                      });
                    } else {
                      await feedClient?.markAsSeen(item);
                      Analytics.inboxItemStatusChanged({ id: item.id, action: "seen" });
                    }

                    if (item.id === activeItem?.id) {
                      setActiveItem({
                        ...item,
                        seen_at: item.seen_at ? null : new Date().toISOString(),
                      });
                    }
                  }}
                >
                  Mark as {item.seen_at ? "unseen" : "seen"}
                </Menu.Item>
              </Menu.Group>

              {feed !== "updates" && (
                <>
                  <Menu.Separator />
                  <Menu.Group>
                    <Menu.Item onClick={(event) => handleArchivalClick({ event, item })}>
                      {item.archived_at ? "Unarchive" : "Archive"}
                    </Menu.Item>
                  </Menu.Group>
                </>
              )}
            </Menu>

            {!item.seen_at && (
              <ItemIndicators>
                {!item.seen_at && (
                  <StatusIndicator status="positive" css={{ margin: 3, width: 8, height: 8 }} />
                )}
              </ItemIndicators>
            )}
          </ItemToolbar>
        </ItemBox>
      </NavigationMenu.Link>
    </ItemRoot>
  );
}

const NavList = styled(NavigationMenu.List, {
  margin: 0,
  padding: 0,
  listStyle: "none",
});

export function Feed<T = GenericData>({
  feed,
  emptyTitle,
  emptyDescription,
  emptyIcon,
}: {
  feed: InboxFeed;
  emptyTitle?: string;
  emptyDescription?: string;
  emptyIcon?: React.ReactElement;
}) {
  const { feedClient, activeItem } = useInboxContext();

  const { loading, pageInfo, totalItems, items } = useInboxStore(
    feed,
    (state) => ({
      loading: state.loading,
      pageInfo: state.pageInfo,
      totalItems: state.metadata.total_count,
      items: state.items as InboxFeedItem[],
    }),
    shallow
  );

  const observer = useRef<IntersectionObserver | null>(null);

  const itemsRef = useRef(items);
  itemsRef.current = items;

  const lastUpdateItem = useCallback(
    (node: Element) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries: IntersectionObserverEntry[]) => {
        if (entries[0].isIntersecting && pageInfo.after) {
          feedClient?.fetch({ after: pageInfo.after });
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, feedClient, pageInfo.after]
  );

  const filteredItems = useMemo(
    () => items.filter((item) => (feed === "archived" ? item.archived_at : !item.archived_at)),
    [feed, items]
  );
  const renderedItems = useMemo(() => {
    const indexOfFirstOnboardingItem = filteredItems.findIndex((item) => {
      return [TaskType.CompanyOnboardChecklist, TaskType.OpeningBalance].includes(
        item.data?.messageType
      );
    });

    return (
      <NavigationMenu.Root orientation="vertical" value={activeItem?.id}>
        <NavList>
          {/** TODO virtualize in the future? */}
          {filteredItems.map((item, index) => {
            if (index === indexOfFirstOnboardingItem) {
              return (
                <>
                  <LabelContainer key={`header:${item.id}`}>
                    <NavGetStarted />
                    <Text variant="bodyXS" color="white">
                      Getting started checklist
                    </Text>
                  </LabelContainer>
                  <Item item={item} key={item.id} />
                </>
              );
            }

            return <Item item={item} key={item.id} />;
          })}
        </NavList>
      </NavigationMenu.Root>
    );
  }, [activeItem?.id, filteredItems]);

  return (
    <Root>
      {loading ? (
        <Box
          css={{
            minHeight: "100%",
            alignSelf: "center",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loader size={48} />
        </Box>
      ) : filteredItems.length === 0 ? (
        <Stack
          gap="3"
          direction="vertical"
          css={{
            minHeight: "100%",
            alignSelf: "center",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {emptyIcon}

          <Stack
            gap="1h"
            direction="vertical"
            css={{
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              maxWidth: 300,
            }}
          >
            <Text variant="headingS" color="gray50">
              {emptyTitle}
            </Text>
            <Text variant="bodyS" color="gray400">
              {emptyDescription}
            </Text>
          </Stack>
        </Stack>
      ) : (
        <>
          {renderedItems}

          {pageInfo.after && items.length < totalItems && (
            <Text
              variant="bodyS"
              color="gray200"
              css={{
                marginTop: "$2",
                textAlign: "center",
                display: "block",
              }}
              // @ts-expect-error passing void callback as ref
              ref={lastUpdateItem}
            >
              {loading ? "Loading" : "Scroll for more"}
            </Text>
          )}
        </>
      )}
    </Root>
  );
}
