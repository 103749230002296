import React, { useMemo } from "react";
import MiniSearch from "minisearch";
import { groupBy, keys, pickBy, keyBy, noop } from "lodash";

import { capitalizeName } from "@puzzle/utils";
import { AutocompleteMenu, Select, Button, styled, Toolbar } from "@puzzle/ui";

import { CategoryFragment, LedgerAccountType } from "graphql/types";
import useCategories from "./hooks/useCategories";
import { useChartOfAccounts } from "components/dashboard/Accounting/shared/useChartOfAccounts";
import { zIndex } from "ve";

const Footer = styled("div", {
  display: "flex",
  height: "40px",
  padding: "$0h $1h",
  justifyContent: "space-between",
  alignItems: "center",
  alignSelf: "stretch",
  borderTop: "1px solid $rhino200",
});

const StyledButton = styled(Button, {
  "&:hover": {
    color: "$green700 !important",
  },
});

type Props = {
  onCategoriesChange: (values: CategoryFragment[]) => void;
  selectedCategories: CategoryFragment[];
  categoryFilter?: "expense" | "capitalizable";
  trigger?: React.ReactElement;
  subMenuTrigger?: string;
  footer?: boolean;
  disabled?: boolean;
};

export function useExpenseCategories() {
  const { categories } = useCategories();
  const { categories: coaCategories } = useChartOfAccounts();

  const types = coaCategories?.map(({ displayId, type }) => ({ id: displayId, type }));
  const categoryTypes = keyBy(types, "id");
  const revenueAndContra = categories.filter((c) => {
    if (!c.coaDisplayId || !categoryTypes[c.coaDisplayId]) return false;
    return [LedgerAccountType.Revenue, LedgerAccountType.ContraRevenue].includes(
      categoryTypes[c.coaDisplayId].type
    );
  });

  // hardcoded ordering on display name based on expected
  // prevelence of usage of given category on an invoice
  const inverseCategoryDisplayOrder = [
    "Uncategorized Revenue",
    "Miscellaneous Revenue",
    "Usage Revenue",
    "Subscription Revenue",
    "Partnership Revenue",
    "Services Revenue",
    "Transaction Revenue",
  ];

  const expenseCategories = [...revenueAndContra].sort((a, b) => {
    if (!a.displayName || !b.displayName) return 1;
    const aIndex = inverseCategoryDisplayOrder.indexOf(a.displayName);
    const bIndex = inverseCategoryDisplayOrder.indexOf(b.displayName);
    // higher returned index should go first
    // not in list (-1) will go at the end
    return bIndex - aIndex;
  });

  return { expenseCategories };
}

export const CategoriesFilter = ({
  onCategoriesChange,
  selectedCategories,
  categoryFilter,
  trigger,
  subMenuTrigger,
  footer = false,
  disabled = false,
}: Props) => {
  const { categories, categoriesByPermaKey, capitalizableCategories } = useCategories();
  const { expenseCategories } = useExpenseCategories();
  const displayedCategories = useMemo(() => {
    if (categoryFilter === "expense") {
      return expenseCategories;
    }

    if (categoryFilter === "capitalizable") {
      return capitalizableCategories;
    }

    return categories;
  }, [capitalizableCategories, expenseCategories, categories, categoryFilter]);

  const filteredOptions = useMemo(
    () => displayedCategories.filter((category) => !category.deprecated),
    [displayedCategories]
  );

  const duplicateCategories = useMemo(
    () => keys(pickBy(groupBy(displayedCategories, "coaDisplayId"), (c) => c.length > 1)),
    [displayedCategories]
  );

  const miniSearch = useMemo(() => {
    const instance = new MiniSearch<CategoryFragment>({
      fields: ["coaDisplayId", "name", "examples", "description"],
      idField: "coaKey",
      searchOptions: {
        boost: { name: 2, coaDisplayId: 1.5, examples: 0.5, description: 0.5 },
        prefix: true,
        fuzzy: 0.2,
      },
    });

    instance.addAllAsync(filteredOptions);

    return instance;
  }, [filteredOptions]);

  const _trigger = trigger ? (
    trigger
  ) : (
    <Toolbar.Button variant="menu" asChild disabled={disabled}>
      <Select
        variant="filter"
        size="small"
        options={[]}
        placeholder="Categories"
        onSelectionChange={noop}
      />
    </Toolbar.Button>
  );

  return (
    <AutocompleteMenu<CategoryFragment, true, false, false>
      getOptionLabel={(option) => {
        const { coaDisplayId, coaKey } = option;
        const isDuplicate = coaDisplayId && duplicateCategories.includes(coaDisplayId);
        const suffix =
          isDuplicate && coaKey ? ` (${capitalizeName(coaKey.replaceAll("_", " "))})` : "";

        return `${option.name}${suffix}`;
      }}
      getOptionKey={(o) => o.permaKey}
      options={filteredOptions}
      value={selectedCategories}
      subMenuTrigger={subMenuTrigger}
      onChange={(_, newValue) => {
        onCategoriesChange(newValue);
      }}
      trigger={_trigger}
      label="Pick categories"
      multiple
      css={{ zIndex: zIndex("subMenu") }}
      filterOptions={(options, state) => {
        const i = state.inputValue;

        if (!i) {
          return options;
        }

        return miniSearch.search(i).flatMap((result) => categoriesByPermaKey?.[result.id] ?? []);
      }}
      isOptionEqualToValue={(option, value) => option.permaKey === value.permaKey}
      footer={
        footer ? (
          <Footer>
            <StyledButton
              variant="minimal"
              size="small"
              onClick={() => onCategoriesChange(filteredOptions)}
            >
              Select all
            </StyledButton>
            <StyledButton variant="minimal" size="small" onClick={() => onCategoriesChange([])}>
              Clear
            </StyledButton>
          </Footer>
        ) : (
          <></>
        )
      }
    />
  );
};
