import React from "react";
import { IntegrationsListItemBaseProps } from "../List/types";
import ConnectionStatusButton from "./ConnectionStatusButton";
import ListItemBase from "./BasicListItemBase";
import { IntegrationConnectionStatus } from "graphql/types";
import { toIntegrationsListItemId } from "../testIds";
import Loader from "../../common/Loader";

const BasicListItem = ({
  endAdornment,
  integrationType,
  connection,
  connectIntegration,
  className,
  logo,
  listItemText,
  title,
  subtitle,
  loading,
  isBeta,
  requiresExternalSetup,
  defaultButtonText,
  isPaidAddOn,
  tooltipContent,
}: IntegrationsListItemBaseProps) => {
  const onClick =
    connection?.status !== IntegrationConnectionStatus.Ok ? connectIntegration : undefined;

  const adornment = loading ? (
    <Loader />
  ) : (
    endAdornment ?? (
      <ConnectionStatusButton
        onClick={onClick}
        status={connection?.status}
        integrationType={integrationType}
        requiresExternalSetup={requiresExternalSetup}
        basic
        defaultButtonText={defaultButtonText}
      />
    )
  );

  return (
    <ListItemBase
      subtitle={subtitle}
      title={title}
      dataTestId={toIntegrationsListItemId(title)}
      variant="compact"
      listItemText={listItemText}
      logo={logo}
      className={className}
      endAdornment={adornment}
      onClick={onClick}
      isBeta={isBeta}
      isPaidAddOn={isPaidAddOn}
      tooltipContent={tooltipContent}
    />
  );
};

export default BasicListItem;
