import { useCallback } from "react";
import useAppRouter, { isCustomersRoute } from "./useAppRouter";
import { RequiredPermissions, Requires, loadingFeatureFlagsVar } from "./VerifyRouteAccessProvider";
import { Route } from "./routes";
import { useActiveCompany } from "components/companies";
import { useReactiveVar } from "@apollo/client";
import useSelf from "components/users/useSelf";
import config from "./config";

const useRouteAccess = () => {
  const { self, loading: _loadingSelf, isPuzzlenaut } = useSelf();
  const loadingFeatureFlags = useReactiveVar(loadingFeatureFlagsVar);
  const loadingSelf = _loadingSelf || loadingFeatureFlags;
  const {
    company,
    membershipRole,
    loading: loadingCompany,
    completedOnboarding,
  } = useActiveCompany();
  const {
    isTempAuthRoute,
    isUnknownRoute,
    isIntegrationRoute,
    isInboxRoute,
    isTransactionRoute,
    isAccountingRoute,
    isVendorRoute,
  } = useAppRouter();

  const hasAccess = useCallback(
    (path: Route) => {
      if (isTempAuthRoute(path)) {
        return true;
      }

      if (isUnknownRoute(path)) {
        return false;
      }

      let permissions = RequiredPermissions[path];
      if (isIntegrationRoute(path)) {
        permissions = RequiredPermissions[Route.integrations];
      } else if (isTransactionRoute(path)) {
        permissions = RequiredPermissions[Route.transactions];
      } else if (isInboxRoute(path)) {
        permissions = RequiredPermissions[Route.inbox];
      } else if (isAccountingRoute(path)) {
        permissions = RequiredPermissions[Route.accounting];
      } else if (isVendorRoute(path)) {
        permissions = RequiredPermissions[Route.vendors];
      } else if (isCustomersRoute(path)) {
        permissions = RequiredPermissions[Route.customers];
      }

      if (permissions.length === 0) {
        return true;
      }

      if (loadingSelf || loadingCompany) {
        return false;
      }

      const forbidden = permissions.some((permission) => {
        if (permission === Requires.Company) {
          if (!company || !self) {
            return permission;
          }
        }

        if (permission === Requires.PermanentUser) {
          if (!self) {
            return permission;
          }
        }

        if (permission === Requires.Puzzle) {
          if (!isPuzzlenaut) {
            return permission;
          }
        }

        if (permission === Requires.CompletedOnboarding) {
          if (!completedOnboarding) {
            return permission;
          }
        }

        if (permission === Requires.CompanyAdmin) {
          return membershipRole !== "Admin" ? permission : false;
        }

        if (permission === Requires.Notifications) {
          if (!config.NOTIFICATIONS_ENABLED) {
            return permission;
          }
        }

        if (permission === Requires.IntegrationsPageVisibility) {
          if (company?.features.hideIntegrationsPage) {
            return permission;
          }
        }

        if (permission === Requires.UsersPageVisibility) {
          if (company?.features.hideUsersPage) {
            return permission;
          }
        }
      });

      return !forbidden;
    },
    [
      loadingSelf,
      loadingCompany,
      company,
      self,
      isPuzzlenaut,
      completedOnboarding,
      membershipRole,
      isAccountingRoute,
      isIntegrationRoute,
      isTransactionRoute,
      isVendorRoute,
      isInboxRoute,
      isUnknownRoute,
      isTempAuthRoute,
      isCustomersRoute
    ]
  );

  return {
    hasAccess,
  };
};

export default useRouteAccess;
