import React from "react";
import { Stack, Text, vars } from "ve";
import { Check, CircledIcon } from "@puzzle/icons";
import { RipplingStep } from "./ConnectRipplingModal";

export const RipplingStepCard = ({
  step,
  stepNumber,
  href,
  completed = false,
  onClick,
}: {
  step: RipplingStep;
  stepNumber: number;
  href: string;
  completed?: boolean;
  onClick?: () => void;
}) => {
  const renderCircledIcon = () => {
    const iconSize = 40;

    if (!completed) {
      return (
        <CircledIcon width={iconSize} height={iconSize} backgroundColor={vars.colors.blackA32}>
          <Text color="white">{stepNumber.toString()}</Text>
        </CircledIcon>
      );
    }
    return (
      <CircledIcon width={iconSize} height={iconSize} backgroundColor={vars.colors.green60}>
        <Check stroke={vars.colors.greenalpha} />
      </CircledIcon>
    );
  };

  return (
    <a href={href} onClick={onClick} style={{ textDecoration: "none", outlineColor: vars.colors.gray500 }}>
      <Stack
        direction="horizontal"
        style={{
          padding: vars.space["2h"],
          borderRadius: vars.space["$1"],
          backgroundColor: "rgba(255, 255, 255, 0.1)",
          alignItems: "center",
        }}
      >
        {renderCircledIcon()}
        <Stack direction="vertical" gap={vars.space["$1"]}>
          <Text variant="bodyM" color="white">
            {step.title}
          </Text>
          <Text variant="bodyS" color="gray300">
            {step.subText}
          </Text>
        </Stack>
      </Stack>
    </a>
  );
};
